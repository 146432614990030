import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SettingItem, SettingsKeys, SettingType } from '../../model/setting.model';

@Component({
  selector: 'app-setting-item',
  templateUrl: './setting-item.component.html',
  styleUrls: ['./setting-item.component.scss']
})
export class SettingItemComponent implements OnInit {
  @Input() setting!: SettingItem;
  @Input() form!: FormGroup;
  @Input() disabled!: boolean;
  @Input() errors!: Array<string>;

  SettingType = SettingType;
  SettingKey = SettingsKeys;
  get isValid() { return this.form.controls[this.setting.settingKey].valid; }


  constructor() {
  }

  ngOnInit(): void {
    this.setting.displayValues?.sort((a, b) => a.order - b.order)
  }

}
