import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {EmailDTO} from "../models/PhishingAttributesModel";
import {UserService} from "../services/user.service";

@Component({
    selector: 'app-email-listing',
    templateUrl: './email-listing.component.html',
    styleUrls: ['./email-listing.component.css']
})
export class EmailListingComponent implements OnInit, OnChanges {

    @Input() isLoading: boolean = false;
    @Input() disableScroll: boolean = false;
    @Input() disableSelection: boolean = false;
    @Input() emails: EmailDTO[] = [];
    @Output() emailSelectedEmitter = new EventEmitter<EmailDTO>();

    colors = Array.from({length: 20}, () => this.getRandomColor());

    emailSelected: number[] = [];

    scrollSelector = ".main-panel"

    constructor(public userService: UserService,) {

    }

    ngOnInit(): void {
    }

    selectEmail($event: any) {
        let email = this.emails[$event]

        if (email) {
            email.isSelected = true;
            this.emailSelectedEmitter.next(email);
        }
    }
    rightAnswer(answer: boolean, userAnswer: boolean | undefined): boolean {
        return userAnswer != undefined && answer === userAnswer;
    }

    wrongAnswer(answer: boolean, userAnswer: boolean | undefined): boolean {
        return userAnswer != undefined && answer != userAnswer;
    }
    selectFirst() {
        let first = this.emails[0]
        first.isSelected = true
        this.emailSelected = [0]
    }

    clearSelected() {
        this.emailSelected = [];
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.['emails'])
            this.emails = changes["emails"].currentValue;
    }


    getNextUnanswered(currentIndex: number): number {
        const totalQuestions = this.emails.length;

        // Check if the current index is within bounds
        if (currentIndex < 0 || currentIndex >= totalQuestions) {
            throw new Error('Invalid current index');
        }

        // Start searching from the next index
        let nextIndex = (currentIndex + 1) % totalQuestions;

        // Loop through the array to find the next unanswered question
        while (nextIndex !== currentIndex) {
            if (this.emails[nextIndex].user_judgment_is_legit === undefined) {
                return nextIndex;
            }
            nextIndex = (nextIndex + 1) % totalQuestions;
        }

        // Check if the current question itself is unanswered
        if (this.emails[nextIndex].user_judgment_is_legit === undefined) {
            return currentIndex;
        }

        // If all questions are answered, return -1
        return -1;
    }



    nextEmail() {
        let nextIndex = this.emailSelected[0];

        nextIndex = this.getNextUnanswered(nextIndex);

        if(nextIndex != -1){
            let next = this.emails[nextIndex]
            next.isSelected = true
            this.emailSelected = [nextIndex]
            this.selectEmail(nextIndex)
        }
    }

    getInitials(name: string) {
        const words = name.split(' ');
        let initials = words[0].charAt(0).toUpperCase();
        if (words.length > 1)
            initials += words[1].charAt(0).toUpperCase();
        return initials;
    }

    getRandomColor(): string {
        const textColor = '#FFFFFF';
        let color = '#';

        do {
            color = '#' + Math.floor(Math.random() * 16777215).toString(16);
        } while (this.getContrastRatio(color, textColor) < 4.5);

        return color;
    }

    getContrastRatio(color1: string, color2: string): number {
        const lum1 = this.getLuminance(color1);
        const lum2 = this.getLuminance(color2);
        const brightest = Math.max(lum1, lum2);
        const darkest = Math.min(lum1, lum2);
        return (brightest + 0.05) / (darkest + 0.05);
    }

    getLuminance(color: string): number {
        const hex = color.replace('#', '');
        const r = parseInt(hex.substr(0, 2), 16) / 255;
        const g = parseInt(hex.substr(2, 2), 16) / 255;
        const b = parseInt(hex.substr(4, 2), 16) / 255;
        const gammaR = (r <= 0.03928) ? (r / 12.92) : Math.pow(((r + 0.055) / 1.055), 2.4);
        const gammaG = (g <= 0.03928) ? (g / 12.92) : Math.pow(((g + 0.055) / 1.055), 2.4);
        const gammaB = (b <= 0.03928) ? (b / 12.92) : Math.pow(((b + 0.055) / 1.055), 2.4);
        return (0.2126 * gammaR) + (0.7152 * gammaG) + (0.0722 * gammaB);
    }
}
