<div *ngIf="isLoadingFile" class="slider-container d-flex justify-content-center">
    <mat-progress-spinner diameter="30" mode="indeterminate">
    </mat-progress-spinner>

</div>

<!--<ngx-extended-pdf-viewer *ngIf="!isLoadingFile"-->
<!--                         [showToolbar]="false"-->
<!--                         [src]="file" style="width: 100%; height: 100%">-->
<!--</ngx-extended-pdf-viewer>-->

<pdf-viewer [src]="file" *ngIf="!isLoadingFile"
            [render-text]="true"
            [original-size]="true"
            [fit-to-page]="false"
            style="height: 500px" zoom-scale="page-height">
</pdf-viewer>
