import {Injectable} from '@angular/core';
import {AbstractControl, FormArray, ValidationErrors, ValidatorFn} from '@angular/forms';
import * as moment from 'moment'
import {CountryCode, isPossiblePhoneNumber} from 'libphonenumber-js/max';



@Injectable({
    providedIn: 'root'
})
export class ValidatorsService {
    static domainRegEx = '^(?:(?:http|https)://)?(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[A-Za-z\\u00a1-\\uffff0-9]+-?)*[A-Za-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[A-Za-z\\u00a1-\\uffff0-9]+-?)*[A-Za-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[A-Za-z\\u00a1-\\uffff]{2,}))))(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
    static ipRegEx = '^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$';
    static ipAlphaNumeric = '^[A-Za-z0-9]+$';
    static urlRegEx = '^(https?|ftp|file)://[-a-zA-Z0-9+&@#/%?=~_|!:,.;]*[-a-zA-Z0-9+&@#/%=~_|]';
    constructor() { }

    requiredIfFalse(targetControl: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.parent) {
                return null;
            }
            const target = control.parent.get(targetControl);
            if (!target) {
                return null;
            }
            const violation = !control.value && !target.value;
            return violation ? { smartRequired: { value: control.value } } : null;
        };
    }

    isDomain(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control || !control.value) {
                return null;
            }
            return control.value.match(ValidatorsService.domainRegEx) || control.value.match(ValidatorsService.ipRegEx) ? null : { pattern: { value: control.value } };
        };
    }
    isEmail(): ValidatorFn {
        const emailRegEx = '^[a-zA-Z0-9.!#$%&\'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?([\.][a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$';
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control || !control.value) {
                return null;
            }
            return control.value.match(emailRegEx) ? null : { email: true };
        };
    }

    validColorFormat(): ValidatorFn {
        const hexColorFormat = '^#(?:[0-9a-fA-F]{3}){1,2}$';
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control || !control.value) {
                return null;
            }
            return control.value.match(hexColorFormat) ? null : { colorFormat: true };
        };
    }

    isUser(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control || !control.value) {
                return null;
            }
            return typeof control.value === 'object' ? null : { requiredUser: { value: control.value } };
        };
    }

    autocompleteObjectValidator(myArray: any[]): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            const selectboxValue = c.value;
            const exist = myArray.find(
                (alias) => alias.name === selectboxValue
            );

            if (exist) {
                // everything's fine. return no error.
                return null;
            } else {
                // there's no matching selectboxvalue selected. so return match error.
                return { match: true };
            }
        };
    }

    minSelectedCheckboxes(min = 1): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const formArray = control as FormArray;
            const totalSelected = formArray.controls
                // get a list of checkbox values (boolean)
                .map(control => control.value)
                // total up the number of checked checkboxes
                .reduce((prev, next) => next ? prev + next : prev, 0);

            // if the total is not greater than the minimum, return the error message
            return totalSelected >= min ? null : { required: true };
        };
    }

    dateRangeValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const startDateString = control.get('startDate')!.value;
            const endDateString = control.get('endDate')!.value;

            const startDate = startDateString ? new Date(startDateString) : null;
            const endDate = endDateString ? new Date(endDateString) : null;
            if (startDate && endDate) {
                startDate.setHours(0, 0, 0, 0);
                endDate.setHours(0, 0, 0, 0);

                if (startDate.getTime() > endDate.getTime()) {
                    control.get('startDate')!.setErrors({ 'startDateAfterEndDate': true });
                    control.get('startDate')!.markAsTouched();
                    return { 'startDateAfterEndDate': true };
                } else {
                    control.get('startDate')!.setErrors(null);
                }
            }
            return null;
        };
    }

    isAfterDate(date: Date | null): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (control.value) {
                const controlDate = moment(control.value);
                if (date && controlDate.isSameOrBefore(moment(date))) {
                    return { dateBefore: true };
                }
            }
            return null;
        };
    }

    isAfterDateOrSame(date: string | null): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (control.value) {
                const controlDate = moment(control.value);
                if (date && controlDate.isBefore(moment(date))) {
                    return { dateBefore: true };
                }
            }
            return null;
        };
    }

    isDiffInDaysEqual(date1: Date | null, numOfDays: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (control.value && date1) {
                const date2 = new Date(control.value);
                const diff = date2.getTime() - new Date(date1).getTime();
                const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
                if (diffDays != numOfDays) {
                    return { diffInDaysNotEqual: true };
                }
            }
            return null;
        };
    }

    isTimeInRange(left: string, right: string, userTimezoneOffset: string, mspTimezoneOffset: string, duration: number = 0): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (control.value) {
                const dateInput = moment(control.value).format('YYYY-MM-DDTHH:mm');
                const utcStartDate = moment(dateInput, 'YYYY-MM-DDTHH:mm').utcOffset(userTimezoneOffset, true).utc();
                const utcEndDate = moment(dateInput, 'YYYY-MM-DDTHH:mm').utcOffset(userTimezoneOffset, true).utc().add(duration, 'minutes');
                const startTimeWithMSPTimezone = utcStartDate.utc().utcOffset(mspTimezoneOffset).format('HH:mm');
                const endTimeWithMSPTimezone = utcEndDate.utc().utcOffset(mspTimezoneOffset).format('HH:mm');
                if (left > startTimeWithMSPTimezone || startTimeWithMSPTimezone >= right || endTimeWithMSPTimezone > right) {
                    return { timeInRange: true };
                }
            }
            return null;
        };
    }

    isDayWithin(days: number[], userTimezoneOffset: string, mspTimezoneOffset: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (control.value) {
                const dateInput = moment(control.value).format('YYYY-MM-DDTHH:mm');
                const utcStartDate = moment(dateInput, 'YYYY-MM-DDTHH:mm').utcOffset(userTimezoneOffset, true).utc();
                const date = new Date(utcStartDate.utc().utcOffset(mspTimezoneOffset).format('YYYY-MM-DD HH:mm:ss'));
                if (!days.includes(date.getDay())) {
                    return { dayWithin: true };
                }
            }
            return null;
        };
    }

    isBeforeOrSameDate(date: Date): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (control.value) {
                const controlDate = moment(control.value);
                if (controlDate.isAfter(moment(date))) {
                    return { dateAfter: true };
                }
            }
            return null;
        };
    }

    isBeforeDate(date: Date): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (control.value) {
                const controlDate = moment(control.value);
                if (controlDate.isSameOrAfter(moment(date))) {
                    return { dateAfter: true };
                }
            }
            return null;
        };
    }
    noWhitespaceValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const isWhitespace = control.value && (control.value || '').trim().length === 0;
            const isValid = !isWhitespace;
            return isValid ? null : { whitespace: true };
        };
    }
    noSpaceValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const haveSpace = control.value.includes(' ');
            const isValid = !haveSpace;
            return isValid ? null : { space: true };
        };
    }

    isValidExtensionsList(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const extensionsList = control.value.split(',');
            let isValid = true;
            extensionsList.forEach((extension: string) => {
                if (!extension.trim().toLowerCase().match(ValidatorsService.ipAlphaNumeric)) {
                    isValid = false;
                }
            });
            return isValid ? null : { isValidExtensionsList: true };
        };
    }

    isNumber(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const isNum = (!isNaN(parseInt(control.value)) && isFinite(control.value));
            return isNum ? null : { isNumber: { value: control.value } };
        };
    }

    max(max: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (null == max) {
                return null;
            }
            const exceededLimit = control.value > max;
            return exceededLimit ? { max: { value: control.value } } : null;
        };
    }

    min(min: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (null == min) {
                return null;
            }
            const exceededLimit = control.value < min;
            return exceededLimit ? { min: { value: control.value } } : null;
        };
    }

    areEqual(currentControlName: string, name: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.parent) {
                return null;
            }
            const passwordChange = control.parent.get(name);
            const passwordChangeConfirm = control.parent.get(currentControlName);
            if (passwordChange == null || passwordChangeConfirm == null || passwordChange.value == "" || passwordChangeConfirm.value == "") {
                return null;
            }
            const violation = passwordChange.value !== passwordChangeConfirm.value;
            if (violation){
                return {
                    areEqual: { value: control.value }
                }
            }
            else {
                passwordChange.setErrors(null);
                passwordChangeConfirm.setErrors(null);
                return null;
            }
        };
    }
    checkMatchValidator(field1: string, field2: string): ValidatorFn {
        return function(control: AbstractControl) {
            const field1Value = control.get(field1)?.value;
            const field2Value = control.get(field2)?.value;

            if (field1Value !== '' && field1Value !== field2Value) {
                control.get(field1)?.setErrors({ areEqual: { value: field1Value } });
                return { areEqual: { value: field1Value } };
            }
            return null;
        };
    }
    patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                // if control is empty return no error
                return null;
            }
            // test the value of the control against the regexp supplied
            const valid = regex.test(control.value);
            // if true, return no error (no error), else return error passed in the second parameter
            return valid ? null : error;
        };
    }

    isPossiblePhoneNumber(countryCode: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const phoneNumber = control.value.trim();
            if (control.value.trim().startsWith('+') || control.value.trim().startsWith('00')) {
                return { isPossiblePhoneNumber: { value: phoneNumber } };
            }

            try {
                return (isPossiblePhoneNumber(phoneNumber, countryCode as CountryCode)) ? null : { isPossiblePhoneNumber: { value: phoneNumber } };
            } catch (error) {
                return { isPossiblePhoneNumber: { value: control.value }};
            }
        };
    }
}
