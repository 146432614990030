import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-reusable-dialog',
  templateUrl: './reusable-dialog.component.html',
  styleUrls: ['./reusable-dialog.component.css']
})
export class ReusableDialogComponent {

  content: string = '';
  title: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<ReusableDialogComponent>
  ) {
    this.content = this.data.data;
    this.title = this.data.title;
  }

  onCloseClicked(): void {
    this.dialogRef.close();
  }
}
