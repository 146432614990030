import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from "@angular/router";
import { UserDataStoreService } from "src/app/shared/service/user-data-store.service";


@Injectable({ providedIn: 'root' })
export class AnonymousGuard implements CanActivate, CanActivateChild {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userDataService: UserDataStoreService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.isAnonymous();
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.isAnonymous();
  }

  isAnonymous() {
    const currentUser = this.userDataService.getUser();
    if (!currentUser) {
      return true;
    } else {
      this.router.navigate(['home'], { replaceUrl: true });
    }
    return false;

  }
}
