<div style="-webkit-column-count: 3;
            -moz-column-count: 3;
            column-count: 3;
            -webkit-column-gap: 16px;
            -moz-column-gap: 16px;
            column-gap: 16px;">
    <span *ngFor="let card of [].constructor(numberOfCards)" style="display: inline-block;
                            margin: 0 0 16px;
                            width: 100%;">
        <app-loading-card></app-loading-card>
    </span>
</div>