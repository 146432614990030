import {Component, Input, OnChanges, SimpleChanges,} from '@angular/core';
import {EmailDTO} from '../models/PhishingAttributesModel';
import {UserDataStoreService} from '../shared/service/user-data-store.service';
import {UserDTO} from '../shared/model/user.model';
import {EmailService} from '../shared/service/email.service';
import {finalize} from 'rxjs';

@Component({
    selector: 'app-view-email',
    templateUrl: './view-email.component.html',
    styleUrls: ['./view-email.component.css']
})
export class ViewEmailComponent implements OnChanges {
    @Input() email!: EmailDTO;
    htmlContent: string = "";
    from: string = "";
    to: string = "test@gmail.com";
    subject: string = "";
    attachment: string[] = [];
    loadingEmailBody: boolean = true;

    loggedInUser!: UserDTO;
    emailDomain!: string;

    doc!: Document
    url: string = ""

    constructor(
        public userDataStoreService: UserDataStoreService,
        protected emailService: EmailService
    ) {
        this.loggedInUser = this.userDataStoreService.getUser();
        this.emailDomain = this.loggedInUser.email.split("@")[1]
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.email = changes["email"].currentValue;
        this.email.senderAddress = this.email?.senderAddress.replaceAll("[[userDomain]]", this.emailDomain)
        this.from = this.email.senderAddress;
        this.subject = this.email.subject;
        let attachments: string[] = [];
        if (this.email.emailPhishingAttributes) {
            if (this.email.emailPhishingAttributes.attachments)
                attachments = this.email.emailPhishingAttributes.attachments.split(',');
        }
        this.attachment = attachments;
        this.loadingEmailBody = true;
        this.emailService.getEmailBody(this.email.id, false, this.email?.fileName)
            .pipe(finalize(() => this.loadingEmailBody = false))
            .subscribe(async (blob) => {
                let data = await new Response(blob).text()

                data = this.emailService.replaceEmailConstants(data, this.loggedInUser, this.emailDomain)

                const parser = new DOMParser();

                this.doc = parser.parseFromString(data, 'text/html');
                this.doc = this.emailService.appendHoverStyle(this.doc)

                const serializer = new XMLSerializer();
                this.htmlContent = serializer.serializeToString(this.doc);

                this.htmlContent = this.emailService.editDisplayURLS(this.htmlContent, this.email.emailPhishingAttributes?.linkDisplayURL);
            });

    }


}
