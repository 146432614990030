<div class="br-5" style="height: 76px; background-color: var(--mail-toolbar-color)">
    <div class="d-flex " style="height: 36px">
        <div class="d-flex align-items-center" style="width: 40%; float: left">
            <mat-icon class="m-3">menu</mat-icon>
            <span class="tab">Home</span>
            <span class="tab">View</span>
            <span class="tab">Help</span>

        </div>

    </div>

    <div class="d-flex align-items-center" style="height: 40px; opacity: 0.35">
        <div class="ms-1 d-flex" style="border-radius: 3px; padding: 5px;border: 1px solid #C0C0C0;">
            <mat-icon>mail_outline</mat-icon>
            <span class="px-2">New mail</span>
            <mat-icon class="new-mail-expand">expand_more</mat-icon>
        </div>

        <div class="d-flex ms-3">
            <img src="assets/icons/{{userService.getThemeColor()}}/icons8-trash.svg">
            <mat-icon class="me-1" style="color: #444444">expand_more</mat-icon>

            <img class="mx-3" src="assets/icons/{{userService.getThemeColor()}}/icons8-box.svg"
                 style="width: 24px; height: 24px">
            <img class="ms-3" src="assets/icons/icons8-warning.png">
            <mat-icon class="me-1" style="color: #444444">expand_more</mat-icon>

            <img class="mx-3" src="assets/icons/icons8-broom.png">
            <img class="ms-3" src="assets/icons/{{userService.getThemeColor()}}/icons8-folder.svg"
                 style="width: 24px; height: 24px">
            <mat-icon class="me-1" style="color: #444444">expand_more</mat-icon>

            <mat-divider [vertical]="true"></mat-divider>

            <img class="mx-3" src="assets/icons/icons8-reply.png">
            <img class="mx-3" src="assets/icons/icons8-reply-all.png">
            <img class="ms-3" src="assets/icons/icons8-forward.png">
            <mat-icon style="color: #444444">expand_more</mat-icon>

            <mat-divider [vertical]="true"></mat-divider>
            <mat-icon class="mx-3">more_horiz</mat-icon>
        </div>


    </div>
</div>
