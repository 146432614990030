import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {UserService} from "../../services/user.service";
import { AuthTokenService } from 'src/app/services/authtoken.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private userService: UserService, 
        private authTokenService: AuthTokenService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401 || (err.error.code == '300' && err.error.details == 'Token expired')) {
                let parentOrigin = "";
                try {
                    parentOrigin = window.parent.location.origin;
                } catch(err) {
                }
                if(parentOrigin != window.location.origin) {
                    let err: any = { details:"Session has expired, please refresh page" };
                    this.authTokenService.onLoginError(err);
                } else {
                    console.log('Logging out');
                    // auto logout if 401 response returned from api
                    this.userService.logout();
                }
            }

            const error = err.error;
            return throwError(error);
        }))
    }
}
