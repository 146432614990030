<div class="text-center p-4 br-5" style=" height: 91%; overflow-y: auto;  background-color: var(--container-background)">
  <h1>Welcome to the Phishing Simulation</h1>
  <p class="welcome mt-3">Let's help you learn instead of trying to get you in trouble. </p>

  <p class="welcome mt-2">Go through each email and using the right panel decide if each part of the email is phishing
    or legitimate.</p>
  <p class="welcome">After completing all of the emails you will be scored and given your results.</p>
  <p class="welcome">Let's begin.</p>

  <button (click)="startSimulationClicked()" class="mt-3 theme-button" mat-raised-button [disabled]="isLoadingEmails">Start Simulation
  </button>

    <div class="d-flex align-items-center justify-content-center my-4">
        <span> <strong>Hints: </strong></span>
        <div class="d-flex mx-2">
            <div class="icon-with-text" (click)="extraHelpDialogsService.openDialog(HelperFileTypesEnum.SENDER)">
                <mat-icon class="hintIcon">
                    contact_mail
                </mat-icon>
                <span class="icon-text">Sender</span>
            </div>
            <div class="icon-with-text" (click)="extraHelpDialogsService.openDialog(HelperFileTypesEnum.CONTENT)">
                <mat-icon class="hintIcon">
                    chat
                </mat-icon>
                <span class="icon-text">Content</span>
            </div>
            <div class="icon-with-text" (click)="extraHelpDialogsService.openDialog(HelperFileTypesEnum.LINKS)">
                <mat-icon class="hintIcon">
                    language
                </mat-icon>
                <span class="icon-text">Links</span>
            </div>
            <div class="icon-with-text" (click)="extraHelpDialogsService.openDialog(HelperFileTypesEnum.ATTACHMENTS)">
                <mat-icon class="hintIcon">
                    attach_file
                </mat-icon>
                <span class="icon-text">Attachments</span>
            </div>
        </div>
    </div>
</div>
