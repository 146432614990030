export interface UserRole {
    roleID: number;
    roleName: string;
    roleDesc: string;
}

export enum RolesEnum {
    SYSTEM_ADMIN = 1,
    ADMIN = 2,
    COMPANY_ADMIN = 3,
    USER = 4,
    SUPER_ADMIN = 5
}
