import {NgModule} from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {LoginComponent} from "./login/login.component";
import {HomeComponent} from "./home/home.component";
import {SignupComponent} from "./signup/signup.component";
import {LoginErrorComponent} from "./login/login-error/login.error.component";
import {NotFoundComponent} from "./shared/component/not-found/not-found.component";
import { AnonymousGuard } from './core/guards/anonymous.guard';


const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'login',
                component: LoginComponent,
                pathMatch: 'full',
                canActivate: [AnonymousGuard],
            },
            {
                path: 'login-error',
                component: LoginErrorComponent,
                pathMatch: 'full',
            },
            {
                path: 'home',
                component: HomeComponent,
            },
            {
                path: 'admin',
                loadChildren: () =>
                    import('src/app/admin-module/admin-module.module').then(
                        (m) => m.AdminModuleModule
                    ),
            },
            {
                path: 'customers',
                loadChildren: () =>
                    import('src/app/customer/customer.module').then(
                        (m) => m.CustomerModule
                    ),
            },
            {
                path: 'signup',
                component: SignupComponent,
            },
            {
                path: '404',
                component: NotFoundComponent,
                pathMatch: 'full',
            },
            { path: '**', redirectTo: '/home' }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})

export class AppRoutingModule {
}
