import {PhishingAttributesEnums} from "./PhishingAttributesEnums";

export interface ScoreModel {
  id: number;
  scores: Map<PhishingAttributesEnums, boolean>
}

export class ScoreItem {
  score: number = 0;
  total: number = 0;
  percentage?: string;
}

export interface UserSimulationResultsDTO {
    id: number;
    userId: number;
    name: string
    email: string;
    emailClient: string;
    createDate: string;
    simulationStartTime: string;
    simulationCompleteTime: string;
    overallScore: number;
    detailScore: number;
    totalEmails: number;
    overallEmailCorrect: number;
    totalSenderPhishy: number;
    senderPhishyCorrect: number;
    totalContentPhishy: number;
    contentPhishyCorrect: number;
    totalLinksPhishy: number;
    linksPhishyCorrect: number;
    totalAttachmentPhishy: number;
    attachmentPhishyCorrect: number;
    totalQRCodePhishy: number;
    qrCodePhishyCorrect: number;
}

export interface SimResultCreator {
    userId: number;
    createDate: string;
    simulationStartTime: string;
    simulationCompleteTime: string;
    overallScore: number;
    detailScore: number;
    totalEmails: number;
    overallEmailCorrect: number;
    totalSenderPhishy: number;
    senderPhishyCorrect: number;
    totalContentPhishy: number;
    contentPhishyCorrect: number;
    totalLinksPhishy: number;
    linksPhishyCorrect: number;
    totalAttachmentPhishy: number;
    attachmentPhishyCorrect: number;
    totalQRCodePhishy: number;
    qrCodePhishyCorrect: number;
}

export interface SimResultsFilter {
    userId?: number;
    customerId?: number
}
