import { MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertPopupService } from 'src/app/services/alert.popup.service';
import { ConfirmationModel } from '../../model/generic-confirmation.model';


@Component({
    selector: 'app-generic-confirmation',
    templateUrl: './generic-confirmation.dialog.html',
    styleUrls: ['./generic-confirmation.dialog.css']
})
export class GenericConfirmationDialog {

    constructor(
        protected alertPopupService: AlertPopupService,
        public dialogRef: MatDialogRef<GenericConfirmationDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmationModel

    ) {
    }

    onConfirm = (): void => {
        this.dialogRef.close(true);
    }

    onReject = (): void => {
        this.dialogRef.close(false);
    }

    onDismiss = (): void => {
        this.dialogRef.close();
    }

}

