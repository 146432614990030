import {Component} from '@angular/core';
import {UserService} from "../services/user.service";
import {ExtraHelpDialogsService} from "../services/extra-help-dialogs-service";

@Component({
    selector: 'app-mail-toolbar',
    templateUrl: './mail-toolbar.component.html',
    styleUrls: ['./mail-toolbar.component.css']
})
export class MailToolbarComponent {

    constructor(
        public userService: UserService,
        public extraHelpDialogsService: ExtraHelpDialogsService
    ) {
    }
}
