import { Component, Input, OnInit } from '@angular/core';
import {AbstractControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import { SettingItem } from 'src/app/shared/model/setting.model';

@Component({
  selector: 'app-increment-input',
  templateUrl: './increment-input.component.html',
  styleUrls: ['./increment-input.component.scss']
})
export class IncrementInputComponent implements OnInit {
  @Input() setting!: SettingItem;
  @Input() form!: FormGroup;
  @Input() disabled!: boolean ;
  @Input() errors!: Array<string> ;

  color = 'default';
  constructor() { }

  ngOnInit(): void {
    if(this.disabled)
      this.form.controls[this.setting.settingKey]?.disable();
   }

  _step: number = 1;

  incrementValue(step: number = 1): void {
    this.form.controls[this.setting.settingKey].setValue(this.parseNumber(this.form.controls[this.setting.settingKey].value) + step);
    this.form.controls[this.setting.settingKey].markAsDirty();
  }

  private parseNumber(num: any): number {
    return +num;
  }

  setColor(color: string): void {
    this.color = color;
  }

  getColor(): string {
    return this.color
  }
}
