<div>
  <div [formGroup]="form" class="d-flex justify-content-between align-items-center">
      <mat-label style="flex: 1;">{{setting.settingName}}</mat-label>
      <mat-form-field style="flex: 0.75; max-width: 400px">
          <button mat-icon-button matPrefix aria-label="Remove" [color]="getColor()"
              (click)="incrementValue(-_step)" [disabled]="disabled">
              <mat-icon>remove</mat-icon>
          </button>
          <input matInput [formControlName]="setting.settingKey" type="number" (focus)="setColor('primary')"
              (blur)="setColor('default')" class="small-mat-form" />
          <button mat-icon-button matSuffix aria-label="Add" [color]="getColor()"
              (click)="incrementValue(_step)" [disabled]="disabled">
              <mat-icon>add</mat-icon>
          </button>
        <mat-error> {{errors}}</mat-error>
      </mat-form-field>
  </div>
</div>
