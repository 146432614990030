import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {UserService} from "../services/user.service";
import {AlertPopupService} from '../services/alert.popup.service';
import {RolesEnum} from "../shared/model/user-role.model";
import {Subject} from "rxjs";
import { UserDataStoreService } from '../shared/service/user-data-store.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    public controlGroup!: FormGroup;
    private ngUnsubscribe: Subject<void> = new Subject();

    email: FormControl;
    password: FormControl;
    hide = true;

    constructor(
        private formBuilder: FormBuilder,
        public router: Router,
        public userDataStoreService: UserDataStoreService,
        private userService: UserService,
        private alertPopUpService: AlertPopupService
    ) {
        this.email = this.formBuilder.control('', Validators.compose([Validators.required, Validators.email, Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")]));
        this.password = formBuilder.control('', userDataStoreService.isEXEMode() ? null : Validators.compose([Validators.required]));

        this.controlGroup = this.formBuilder.group({
            email: this.email,
            password: this.password
        });
    }

    ngOnInit() {
    }

    start() {
        this.userService.login(this.controlGroup.value).subscribe({
            next: (data) => {
                // if (data.roleID == RolesEnum.Admin)
                //     this.router.navigate(["/admin"]);
                // else
                    this.router.navigate(["/home"]);
            },
            error: () => {
                this.alertPopUpService.error('Invalid email or password');
            }
        })
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
