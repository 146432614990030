<div class="d-flex justify-content-center" style="height: 100%">
  <div class="container align-middle" style="height: 100%">
    <div class="d-flex justify-content-center align-items-center" style="height: 100%">
      <div class="col-5">
        <mat-card class="p-3" style="border-radius: 10px">
          <div class="mb-3">
            <h2>Sign up</h2>
          </div>

          <mat-card-content class="small-mat-form p-0">
            <form *ngIf="controlGroup" [formGroup]="controlGroup" class="d-flex flex-column justify-account-start col-3"
                  style="width: 100%">

              <mat-form-field floatLabel="auto">
                <mat-label>First Name</mat-label>
                <input formControlName="firstName" matInput placeholder="Enter First Name" required type="text">
                  <mat-error *ngIf="firstName && firstName.invalid">
                      {{fieldErrors('firstName')}}</mat-error>
                <mat-icon class="me-1 icon-prefix-padding" matPrefix>person</mat-icon>
              </mat-form-field>

              <mat-form-field class="mt-2" floatLabel="auto">
                <mat-label>Last Name</mat-label>
                <input formControlName="lastName" matInput placeholder="Enter Last name" required type="text">
                  <mat-error *ngIf="lastName && lastName.invalid">
                      {{fieldErrors('lastName')}}</mat-error>
                <mat-icon class="me-1 icon-prefix-padding" matPrefix>person</mat-icon>
              </mat-form-field>

              <mat-form-field class="mt-2" floatLabel="auto">
                <mat-label>Email</mat-label>
                <input formControlName="email" matInput placeholder="Enter Email" required type="email">
                  <mat-error *ngIf="email && email.invalid">
                      {{fieldErrors('email')}}</mat-error>
                <mat-icon class="me-1 icon-prefix-padding" matPrefix>email</mat-icon>
              </mat-form-field>

              <mat-form-field class="mt-2" floatLabel="auto">
                <mat-label>Password</mat-label>
                <input formControlName="password" matInput placeholder="Password" required
                       [type]="hide_pass ? 'password' : 'text'">
                  <mat-error *ngIf="password && password.invalid">
                      {{fieldErrors('password')[0]}}</mat-error>
                <mat-icon class="me-1 icon-prefix-padding" matPrefix (click)="hide_pass = !hide_pass">
                  {{ hide_pass ? "visibility_off" : "visibility" }}</mat-icon>
              </mat-form-field>

              <mat-form-field class="mt-2" floatLabel="auto">
                <mat-label>Confirm Password</mat-label>
                <input formControlName="confirmPassword" matInput placeholder="Confirm Password" required
                       [type]="hide_confirm ? 'password' : 'text'">
                  <mat-error *ngIf="confirmPassword && confirmPassword.invalid">
                      {{fieldErrors('confirmPassword')}}</mat-error>
                <mat-icon class="me-1 icon-prefix-padding" matPrefix (click)="hide_confirm = !hide_confirm">
                  {{ hide_confirm ? "visibility_off" : "visibility" }}</mat-icon>
              </mat-form-field>

              <mat-form-field class="mt-2" floatLabel="auto">
                <mat-label>Customer</mat-label>
                  <mat-select [disabled]="isSubmitting" placeholder="Select Customer"
                              formControlName="customerId">
                      <mat-option *ngIf="isLoadingCustomers" disabled>
                        <span class="col-12 d-flex flex-row justify-content-center">
                            <mat-spinner color="primary" strokeWidth="1" diameter="20"></mat-spinner>
                        </span>
                      </mat-option>
                      <mat-option *ngIf="!isLoadingCustomers && customers.length==0" disabled>
                          No available Customers
                      </mat-option>
                      <mat-option *ngFor="let option of customers" [value]="option.id">
                          {{option.name}}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="customerId && customerId.invalid">
                      {{fieldErrors('customerId')}}</mat-error>
                <mat-icon class="me-1 icon-prefix-padding" matPrefix>business</mat-icon>
              </mat-form-field>
            </form>
          </mat-card-content>

          <mat-card-footer>
            <div class="d-flex justify-content-center">
              <button class="w-50 p-4" (click)="signUp()" [disabled]="isSubmitting || controlGroup.invalid"
                      mat-raised-button style="background: #469EF6;">
                Sign up
              </button>
            </div>
            <div class="d-flex justify-content-center">
              <a class="w-40 p-4" routerLink="/login" style="text-decoration: none;">
                Back to login
              </a>
            </div>
          </mat-card-footer>
        </mat-card>
      </div>
    </div>
  </div>
</div>
