import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {WelcomeComponent} from "../welcome/welcome.component";
import {EmailListingComponent} from "../EmailListing/email-listing.component";
import {ScoringPanelComponent} from "../scoring-panel/scoring-panel.component";
import {EmailDTO, EmailFilter} from "../models/PhishingAttributesModel";
import {UserService} from "../services/user.service";
import {EmailService} from '../shared/service/email.service';
import {AlertPopupService} from "../services/alert.popup.service";
import {UserDataStoreService} from "../shared/service/user-data-store.service";
import {SettingFilterInput, SettingGroup, SettingPageEnum, SettingsKeys} from "../shared/model/setting.model";
import {CustomerDTO} from "../shared/model/customer.model";
import {SettingService} from "../shared/service/setting.service";
import {UserDTO} from "../shared/model/user.model";
import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit, AfterViewInit {
    @ViewChild("appEmailListing") emailListingComponent!: EmailListingComponent;
    @ViewChild("appWelcome") welcomeComponent!: WelcomeComponent;
    @ViewChild("appScoringPanel") scoringPanelComponent!: ScoringPanelComponent;

    emails: EmailDTO[] = [];
    currentEmail: EmailDTO | undefined;
    simulationStarted = false;
    simulationStartTime!: Date;
    simulationEndTime!: Date;
    simulationFinished = false;
    immediateFeedback = true;

    WELCOME_MESSAGE = "Welcome";
    message = this.WELCOME_MESSAGE;

    isLoadingEmails: boolean = true;
    disableScroll: boolean = false;

    last_email = false;

    constructor(
        private userService: UserService,
        private emailService: EmailService,
        private alertPopupService: AlertPopupService,
        private userDataStoreService: UserDataStoreService,
        private settingService: SettingService
    ) {

    }

    ngOnInit(): void {
        if(!environment.loadDataFromBE)
            this.listEmails(undefined, undefined);
        if(this.userDataStoreService.isAdmin() || this.userDataStoreService.isSuperAdmin()) {
            this.handleAdminAndSuperAdmin();
        } else if(this.userDataStoreService.isUser() || this.userDataStoreService.isCompanyAdmin()) {
            this.handleUser(this.userDataStoreService.getUser());
        }
    }

    private handleAdminAndSuperAdmin() {
        this.userDataStoreService.customerChange$.subscribe((customer) => {
            this.reinitializeComponent(customer);
        })
    }

    private handleUser(user: UserDTO) {
        this.loadSettingsAndEmails(user.customerDTO?.id, user.userID);
    }

    reinitializeComponent(customer: CustomerDTO) {
        this.reset();
        this.simulationStarted = false;
        this.emails = [];
        this.currentEmail = undefined;
        this.isLoadingEmails = true;
        this.disableScroll = false;

        this.emailListingComponent.clearSelected();

        this.userService.handleSimulation(false);

        this.loadSettingsAndEmails(customer.id, undefined);
    }

   loadSettingsAndEmails(customerId: number | undefined, userID: number | undefined) {
        let input: SettingFilterInput = {
            page: SettingPageEnum.CUSTOMER,
            customerId: customerId,
            userId: userID
        }

        this.settingService.listSettings(input)
            .subscribe({
                next: (data: SettingGroup[]) => {
                    data.forEach(group => {
                        group.settingDTOList.forEach(setting => {
                            if (setting.settingKey === SettingsKeys.IMMEDIATE_FEEDBACK) {
                                this.immediateFeedback = setting.value == "1"
                            }
                            if (setting.settingKey === SettingsKeys.SIMULATION_EMAIL_COUNT) {
                                let emailCount = parseInt(setting.value, 10);
                                this.listEmails(emailCount, customerId);
                            }
                        });
                    });
                },
                error: (error) => {
                    this.alertPopupService.error("Failed to load settings");

                }
            });
    }

    listEmails(emailCount: number | undefined, customerID: number | undefined): void {
        this.isLoadingEmails = true
        let filter: EmailFilter ={
            active: true,
            customerID
        }

        this.emailService.listUserEmails(emailCount, filter).then((data) => {
            this.isLoadingEmails = false;
            this.emails = data;
        })
    }

    ngAfterViewInit(): void {
        this.userService.simulationState.subscribe((state) => {
            if(this.emails.length == 0 && state) {
                this.alertPopupService.error("There are no Emails to start the Simulation, please contact the Administrator")
                return;
            }
            if(state != null)
                this.simulationStarted = state;
            this.simulationStartTime = new Date();

            if (state) {
                this.last_email = false;
                this.setupCurrentEmail();
                this.emailListingComponent.selectFirst()
            }
        })

        this.scoringPanelComponent.submitEvent.subscribe((scoringModel) => {
            let index = this.emails.findIndex(value => value.id == scoringModel.id)

            if (index != -1) {
                let temp = this.emails[index]
                temp.user_judgment_is_legit = true;
                scoringModel.scores.forEach((value, key) => {
                    if (!value)
                        temp.user_judgment_is_legit = false;
                });

                temp.scores = scoringModel.scores

                this.emails[index] = temp
            }
        })

        this.scoringPanelComponent.continueEvent.subscribe((emailId) => {
            this.continueToNext()
        })
    }

    continueToNext(){
        let notSubmitted = this.notSubmitted();
        if (notSubmitted.length > 0)
            this.emailListingComponent.nextEmail()

        else {
            this.userService.handleSimulation(null);
            this.message = "Post Engagement Summary";
            this.simulationFinished = true;
            this.simulationEndTime = new Date();
            this.simulationStarted = false;
        }
    }
    private notSubmitted(){
        return this.emails.filter(value => value.user_judgment_is_legit == undefined)
    }

    private setupCurrentEmail() {
        this.currentEmail = this.emails.find(email => !email.user_judgment_is_legit)
    }

    emailSelected(email: EmailDTO) {
        this.currentEmail = email
        let notSubmitted = this.notSubmitted();
        if (notSubmitted.length == 1)
            this.last_email = true;
        else
            this.last_email = false;
    }

    reset() {
        this.simulationFinished = false;
        this.emails.forEach(function (email) {
            email.user_judgment_is_legit = undefined;
            email.scores = undefined
        })
        this.currentEmail = undefined;
        this.message =this.WELCOME_MESSAGE;
    }
}
