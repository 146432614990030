import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpServiceFacade} from "../shared/http.service.facade";
import {Pageable, PageRequest} from "../shared/model/pageable";
import {CustomerCategoriesInput, CustomerDTO, CustomerInput} from "../shared/model/customer.model";
import {UserDTO, UserFilter} from "../shared/model/user.model";
import {HttpParams} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class CustomersService {

    constructor(
        private backendService: HttpServiceFacade,
    ) {

    }

    getCustomers(pageRequest: PageRequest): Observable<Pageable<CustomerDTO>> {
        const params = this.createParams(pageRequest);
        return this.backendService.post<Pageable<CustomerDTO>>('/api/customers/list', params);
    }

    findCustomer(customerId: number): Observable<CustomerDTO> {
        return this.backendService.get<CustomerDTO>('/api/customers/' + customerId);
    }

    updateCustomer(customer: CustomerInput): Observable<CustomerDTO> {
        return this.backendService.put<CustomerDTO>('/api/customers/', customer);
    }

    getCustomerSimUsers(customerId: number, pageRequest: PageRequest): Observable<Pageable<UserDTO>> {
        const params = this.createParams(pageRequest);
        return this.backendService.get<Pageable<UserDTO>>(`/api/customers/${customerId}/users`, params);
    }

    updateCategories(id: number, categories: CustomerCategoriesInput) {
        return this.backendService.put<CustomerDTO>(`/api/customers/${id}/category`, categories);
    }

    createCustomer(customer: CustomerDTO): Observable<CustomerDTO> {
        return this.backendService.post<CustomerDTO>('/api/customers/', customer);
    }

    listUsers(filters: UserFilter): Observable<any> {
        return this.backendService.post('/api/users/', filters);
    }

    createParams(pageRequest: PageRequest): HttpParams {
        let params = new HttpParams();
        if (pageRequest) {
            if (pageRequest.page !== undefined) {
                params = params.set('page', pageRequest.page.toString());
            }
            if (pageRequest.size !== undefined) {
                params = params.set('size', pageRequest.size.toString());
            }
            if (pageRequest.sort !== undefined && pageRequest.direction !== undefined) {
                params = params.set('sort', pageRequest.sort + ',' + pageRequest.direction);
            }
        }
        return params;
    }
}
