import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class AuthTokenService {
    constructor(
        private userService: UserService,
        private router: Router,
        private location: Location
    ) {}

    isAuthTokenPath(path: string, action: string): boolean {
        return path.includes(`/${action}/authtoken`);
    }

    processLogout() {
        const rawAuthToken = this.extractAuthToken();
        this.userService.logoutWithAuthToken(rawAuthToken);
    }

    processLogin() {
        const rawAuthToken = this.extractAuthToken();
        this.userService.loginWithAuthToken(rawAuthToken).subscribe(
            () => this.onLoginSuccess(),
            error => this.onLoginError(error)
        );
    }

    private extractAuthToken(): string {
        const urlObj = new URL(this.location.path(), window.location.origin);
        const urlEncodedAuthToken = urlObj.searchParams.get('authtoken');
        return decodeURIComponent(urlEncodedAuthToken!);
    }

    private onLoginSuccess() {
        window.parent.postMessage('loginSuccess', '*');
        this.router.navigate(['/home']);
    }

    onLoginError(error: any) {
        const errorMessage = error.details || 'Failed to login';
        window.parent.postMessage('loginError', '*');
        this.router.navigate(['/login-error'], { queryParams: { message: errorMessage } });
    }
}
