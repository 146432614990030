import {Component, Input} from '@angular/core';
import {UserService} from "../services/user.service";
import {ExtraHelpDialogsService} from "../services/extra-help-dialogs-service";
import {HelperFileTypesEnum} from "../models/Util";

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent {
    @Input("isLoadingEmails") isLoadingEmails: boolean = true;
    protected readonly HelperFileTypesEnum = HelperFileTypesEnum;

    constructor(
        private userService: UserService,
        public extraHelpDialogsService: ExtraHelpDialogsService
    ) {
    }

    startSimulationClicked() {
        this.userService.handleSimulation(true)
    }
}
