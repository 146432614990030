export class ConfirmationModel {
    title!: string;
    message!: string;
    note!: string;
    confirm!: string;
    reject?: string;
    abort?: string;
    messageHtml?: boolean;
    constructor(title: string, message: string, note: string, confirm: string, abort?: string, reject?: string,
                messageHtml: boolean = false) {
        this.title = title;
        this.message = message;
        this.note = note;
        this.confirm = confirm;
        this.abort = abort;
        this.reject = reject;
        this.messageHtml = messageHtml;
    }
}
