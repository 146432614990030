import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {MatDrawer} from "@angular/material/sidenav";
import {UserService} from "../services/user.service";
import { UserDataStoreService } from '../shared/service/user-data-store.service';
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements AfterViewInit{

  @ViewChild("drawer") drawer!: MatDrawer;
  state: boolean | null= false;

  constructor(
    private router: Router,
    private userDataStoreService: UserDataStoreService,
    private userService: UserService

  ) {
    if(!this.userDataStoreService.getUser())
      this.router.navigate(["/login"])
  }

  ngAfterViewInit() {
    this.drawer.toggle(false, 'mouse')
    this.drawer.toggle(true, 'mouse')

    this.userService.simulationState.subscribe((state) => {
      this.drawer.toggle(!state)
      this.state=state;
    })
  }
}
