import { CustomerDTO } from "./customer.model";

export enum Theme {
    DARK = 'dark-theme',
    LIGHT = 'light-theme'
}

export class LoginRequest {
    email: string;
    password: string;
    constructor(email: string, pass: string) {
        this.email = email;
        this.password = pass;
    }
}

export interface UserDTO {
    userID?: number;
    email: string;
    emailClient: string;
    firstName: string;
    lastName: string;
    roleID: number;
    customerDTO?: CustomerDTO;
    token?: string;
    roleName: string;
    isSSO?: boolean;
    lastLoginTime: string;
}

export class UserFilter {
    roleId!: number;
}

export interface InviteUserInput {
    email: string;
    firstName: string;
    lastName: string;
    roleId: number;
    customerId: number;
}

export interface UpdateUserInput {
    userId: number;
    roleId: number;
    customerId: number;
    email: string;
    firstName: string;
    lastName: string;
}

export interface RegistrationInput {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
    customerId: number;
}

export enum EmailClientsEnum {
    OUTLOOK = "Outlook",
    GMAIL = "Gmail"
}
