<div class="d-flex justify-content-center" style="height: 100%">
  <div class="container align-middle" style="height: 100%">
    <div class="d-flex justify-content-center align-items-center" style="height: 100%">
      <div class="col-5">
        <mat-card class="p-3" style="border-radius: 10px">
          <div class="text-center mb-3">
            <h2>Welcome, Please enter the following info to start the Simulation.</h2>
          </div>

          <mat-card-content class="small-mat-form p-0">
            <form *ngIf="controlGroup" [formGroup]="controlGroup" class="d-flex flex-column justify-account-start col-3"
              style="width: 100%">

              <mat-form-field class="mt-2" floatLabel="auto">
                <mat-label>Email</mat-label>
                <input formControlName="email" matInput placeholder="Enter Email" required type="email">
                <mat-error *ngIf="email && email.hasError('required')">
                  Email is required
                </mat-error>
                <mat-error *ngIf="email && (email.hasError('email') ||email.hasError('pattern') )">
                  Email is invalid
                </mat-error>
                <mat-icon class="me-1 icon-prefix-padding" matPrefix>email</mat-icon>
              </mat-form-field>

              <mat-form-field appearance="outline" floatLabel="auto" class="d-flex justify-content-between" *ngIf="!userDataStoreService.isEXEMode()">
                <mat-label class="small-mat-form">Password</mat-label>
                <input class="small-mat-form" matInput placeholder="Enter your password"
                  [type]="hide ? 'password' : 'text'" formControlName="password" required />
                <mat-icon class="me-1 icon-prefix-padding" matPrefix>password</mat-icon>
                <mat-icon matSuffix  (click)="hide = !hide">
                  {{ hide ? "visibility_off" : "visibility" }}</mat-icon>
                <mat-error *ngIf="password && password.invalid">
                  Password is required</mat-error>
              </mat-form-field>
            </form>
          </mat-card-content>
          <div class="d-flex justify-content-center">
            <div class="w-50 mx-1">
              <button (click)="start()" [disabled]="controlGroup.invalid" class="col-12" mat-raised-button
              style="background: #469EF6; padding: 20px">
              Start
            </button>
            </div>

            <div class="w-50 mx-1">
              <button class="w-100" (click)="router.navigate(['/signup'])" [disabled]="userDataStoreService.isEXEMode()"
                      mat-raised-button style="background: #469EF6; padding: 20px">
                Sign up
              </button>
            </div>
          </div>

          <mat-card-footer>
            <div class="d-flex justify-content-center mt-4">
              <app-theme-toggle></app-theme-toggle>
            </div>
          </mat-card-footer>


        </mat-card>
      </div>
    </div>
  </div>
</div>
