import {AfterViewInit, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ContentType} from "../models/DialogEnums";
import {HelperFileTypesEnum} from "../models/Util";
import {UtilService} from "../services/util.service";
import {finalize} from "rxjs";
import {environment} from "../../environments/environment";
import {FileReaderService} from "../file-reader.service";

@Component({
    selector: 'app-multiple-tabs-dialog',
    templateUrl: './multiple-slides-dialog.component.html',
    styleUrls: ['./multiple-slides-dialog.component.css']
})
export class MultipleSlidesDialogComponent implements AfterViewInit {
    type: HelperFileTypesEnum
    file: any
    isLoadingFile = true
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { type: HelperFileTypesEnum },
        private dialogRef: MatDialogRef<MultipleSlidesDialogComponent>,
        private utilService: UtilService,
        private fileReaderService: FileReaderService
    ) {
        this.type = data.type;
    }

    ngAfterViewInit() {
        this.loadHelperFiles()
    }

    loadHelperFiles() {
        if (!environment.loadDataFromBE) {
            this.fileReaderService.readFile("pdf/" + this.type + ".pdf", 'blob')
                .pipe(finalize(() => this.isLoadingFile = false))
                .subscribe({
                    next: (data) => {
                        this.file = URL.createObjectURL(data);
                    },
                    error: (error) => {
                        console.log(error);
                    }
                })
        }
        else {
            this.utilService.loadHelperFile(this.type)
                .pipe(finalize(() => this.isLoadingFile = false))
                .subscribe(data => {
                    // this.file = new Blob([data.body], {type: 'application/pdf'})
                    let blob = new Blob([data.body], {type: 'application/pdf'})
                    this.file = URL.createObjectURL(blob);
                })
        }
    }
}
