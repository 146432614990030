<mat-accordion multi class="w-100">
    <div *ngFor="let settingGroup of settingGroups;let i=index" style="width: 70%;margin: auto;">
        <mat-card appearance="outlined" *ngIf="settingGroup.groupName == ''" class="m-3">
            <app-setting-group [settingGroup]="settingGroup" [page]="page" [customerId]="customerId"></app-setting-group>
        </mat-card>

        <mat-expansion-panel expanded *ngIf="settingGroup.groupName != ''" class="m-3">
            <mat-expansion-panel-header class="mat-elevation-z3">
                <mat-panel-title style="font-weight: bold;">
                    {{settingGroup.groupName}}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-setting-group [settingGroup]="settingGroup" [page]="page" [customerId]="customerId"></app-setting-group>
            </ng-template>
        </mat-expansion-panel>
    </div>
</mat-accordion>
