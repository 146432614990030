import {MatFormField} from '@angular/material/form-field';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {CountryService} from '../../service/country.service';
import {Observable, Subject, timer} from 'rxjs';
import {Country, CountryRefreshEnum, State} from '../../model/country.model';
import {FormControl, FormGroup} from '@angular/forms';
import {AfterViewInit, Component, Input, OnChanges, OnInit, QueryList, ViewChildren} from '@angular/core';
import {AlertPopupService} from "../../../services/alert.popup.service";


@Component({
    selector: 'app-country-state-city',
    templateUrl: './country-state-city.component.html',
    styleUrls: ['./country-state-city.component.css']
})
export class CountryStateCityComponent implements OnInit, OnChanges, AfterViewInit {

    // Get all the matform fields
    @ViewChildren(MatFormField) formFields!: QueryList<MatFormField>;
    // Update the outline after the view create because the width measured wrong at first time
    ngAfterViewInit(): void {
        // setTimeout(() => this.formFields.forEach(ff => ff.updateOutlineGap()), 300);
    }

    @Input() countryGroup!: FormGroup;

    @Input() refresh: Subject<CountryRefreshEnum> = new Subject();
    citySearchControl!: FormControl;
    private ngUnsubscribe:Subject<void> = new Subject();

    suggestedCountries: Array<Country> = [];

    suggestedStates: Array<State> = [];

    suggestedCities: Array<string> = [];
    filteredCities!: Observable<Array<string>>;

    loadingCountries: boolean = false;

    constructor(
        protected alertPopupService: AlertPopupService,
        private countryService: CountryService,
    ) {
        this.citySearchControl = new FormControl('');
    }
    ngOnChanges(changes: any) {
        this.citySearchControl.patchValue(this.getFormControl('city').value)
        this.refreshStatesAndCities();
    }
    refreshStatesAndCities() {
        this.fetchStatesArray();
        this.fetchCitiesArray();

    }
    ngOnInit() {
        this.fetchCountries();
        this.refresh.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((refreshField: CountryRefreshEnum) => {
                this.handleRefresh(refreshField)
            })

        this.filteredCities = this.citySearchControl.valueChanges.pipe(
            startWith(this.getFormControl('city').value),
            map(
                (val) => {

                    if (this.citySearchControl.value != this.getFormControl('city').value)
                        this.countryGroup.markAsDirty();
                    this.getFormControl('city').patchValue(this.citySearchControl.value);
                    return this.filterCities(val)
                }
            )
        )
    }
    handleRefresh(fieldEnum: CountryRefreshEnum) {

        if (fieldEnum == CountryRefreshEnum.Country_Refresh)
            this.handleCountryChange();
        else if (fieldEnum == CountryRefreshEnum.State_Refresh)
            this.handleStateChange();
        else if (fieldEnum == CountryRefreshEnum.City_Refresh)
            this.handleCityChange();
    }
    filterCities(value: string): Array<string> {
        let filterValue = value.toLowerCase();
        return this.suggestedCities?.filter(city => city.toLowerCase().includes(filterValue))
    }
    fetchCountries() {
        this.loadingCountries = true;
        this.countryService.countriesLoaded
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                () => {
                    this.countryService.getCountries()
                        .pipe(takeUntil(this.ngUnsubscribe))
                        .subscribe(
                            (countries) => {
                                this.loadingCountries = false;
                                this.suggestedCountries = countries;
                                timer(100).subscribe(
                                    () => {
                                        this.refreshStatesAndCities();
                                    }
                                )
                            }
                        )
                }
            )
    }

    compareStrings(s1: string, s2: string): boolean {

        return s1 != "" && s2 != "" && s1.toLowerCase() == s2.toLowerCase();
    }
    handleCountryChange() {
        this.fetchStatesArray();
        this.getFormControl('state').setValue('');
        this.citySearchControl.setValue('');
    }
    fetchStatesArray() {
        this.suggestedStates = this.suggestedCountries?.find(c => c.name.toLowerCase() == this.getFormControl('country').value.toLowerCase())?.states!
    }
    handleStateChange() {
        this.fetchCitiesArray();
        this.citySearchControl.setValue('');
    }

    handleCityChange() {
        this.citySearchControl.patchValue(this.getFormControl('city').value)
    }
    fetchCitiesArray() {
        this.suggestedCities = this.suggestedStates?.find(s => s.name.toLowerCase() == this.getFormControl('state').value.toLowerCase())?.cities!
        // console.log('fetched cities', this.suggestedCities, this.suggestedStates)
    }
    getFormControl(formControlName: string): FormControl {
        return this.countryGroup.get(formControlName) as FormControl;
    }
    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
