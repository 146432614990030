<div class="d-flex justify-content-between align-items-center mx-3">
    <button mat-button (click)="viewConstants()" style="background: #469EF6; color: white">
        View Constants
    </button>

    <button *ngIf="showExpandBtn" (click)="onExpandClick()" class="my-2" mat-mini-fab
        style="background: #469EF6; color: white">
        <mat-icon>{{ isExpanded ? 'expand_more' : 'expand_less' }}</mat-icon>
    </button>

    <button mat-button (click)="onPreviewClick()" style="background: #469EF6; color: white">
        {{inPreview ? 'Original' : 'Preview'}}
    </button>
</div>

<div [ngStyle]="{'height': wrapperHeight}" class="d-flex">

    <nu-monaco-editor [(ngModel)]="htmlContent" [options]="options" (ngModelChange)="valueChange()"
        class="html-edit m-2" style="height: 95%;"></nu-monaco-editor>
    <div [innerHtml]="parsedHtmlContent | sanitizeHtml" (mouseover)="onHover($event)" (click)="onClick($event)"
        class="html-wrapper m-2" style="flex: 1;height:95%;background: white" id="html-viewer">
    </div>
</div>