import {Component, Input, OnInit} from '@angular/core';
import {HttpServiceFacade} from 'src/app/shared/http.service.facade';
import {finalize, takeUntil} from 'rxjs/operators';
import {CustomerDTO} from '../../model/customer.model';
import {Subject} from 'rxjs';
import {FormBuilder, FormControl} from '@angular/forms';
import {AlertPopupService} from "../../../services/alert.popup.service";
import {CustomersService} from "../../../services/customers.service";
import {UserDataStoreService} from "../../service/user-data-store.service";
import {Pageable, PageRequest} from "../../model/pageable";
import {BaseForm} from "../../base-form";


@Component({
    selector: 'app-customer-picker',
    templateUrl: './customer-picker.component.html',
    styleUrls: ['./customer-picker.component.scss']
})
export class CustomerPickerComponent extends BaseForm implements OnInit{

    customerControl!: FormControl
    @Input() editable: boolean = true;

    private ngUnsubscribe: Subject<void> = new Subject();
    isLoadingCustomerCandidates = true;
    customers!: CustomerDTO[];

    constructor(
        private formBuilder: FormBuilder,
        protected override alertPopupService: AlertPopupService,
        public httpServiceFacade: HttpServiceFacade,
        public customerService: CustomersService,
        public userDataStoreService: UserDataStoreService,
    ) {
        super(httpServiceFacade, alertPopupService)
        this.customerControl = this.formBuilder.control('')

        this.controlGroup = this.formBuilder.group({
            customer: this.customerControl
        });
    }

    override ngOnInit() {
        this.loadCustomers();
    }

    loadCustomers() {
        // Load customers if user has an admin role
        if (this.userDataStoreService.isAdmin() || this.userDataStoreService.isSuperAdmin()) {
            this.customerService.getCustomers(new PageRequest(0, 1000))
                .pipe(
                    finalize(() => this.isLoadingCustomerCandidates = false),
                    takeUntil(this.ngUnsubscribe))
                .subscribe((data: Pageable<CustomerDTO>) => {
                    if (data.content) {
                        this.customers = data.content;
                        this.customerControl.setValue(this.customers.at(0)?.id);
                        if(this.customers?.length)
                            this.userDataStoreService.setCustomer(this.customers.at(0)!);
                    }
                    else
                        this.customers = [];
                })
        }
    }

    onCustomerSelected(event: any): void {
        const selectedCustomerId = event.value;
        const selectedCustomer = this.customers.find(customer => customer.id === selectedCustomerId);
        this.userDataStoreService.setCustomer(selectedCustomer!);
    }

    override ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
