import {Component, Input, ViewEncapsulation} from '@angular/core';
import {UserService} from "../../services/user.service";

@Component({
  selector: 'app-email-body',
  templateUrl: './email-body.component.html',
  styleUrls: ['./email-body.component.css'],
  encapsulation: ViewEncapsulation.ShadowDom

})
export class EmailBodyComponent {

    @Input() htmlContent!: any;
    private CUSTOM_URL_ATTRIBUTE = "customurl";

    constructor(
        private userService: UserService,
    ) {
    }

    onClick(event: any) {
        event.preventDefault()
    }


    onHover(event: any) {
        const href = event.target.getAttribute(this.CUSTOM_URL_ATTRIBUTE);

        if (href)
            this.userService.hoveredUrl.next(href)

        else
            this.userService.hoveredUrl.next("")

    }
}
