import {Injectable} from "@angular/core";
import {HttpServiceFacade} from "../shared/http.service.facade";
import {CategoryCreator, CategoryDTO} from "../shared/model/category.model";
import {Observable, of as observableOf} from 'rxjs';
import {Pageable, PageRequest} from "../shared/model/pageable";
import {HttpParams} from "@angular/common/http";
import {SimResultCreator, SimResultsFilter, UserSimulationResultsDTO} from "../models/ScoreModel";

@Injectable({
    providedIn: 'root'
})
export class SimResultsService {

    constructor(
        private backendService: HttpServiceFacade,
    ) {

    }

    addSimulationResults(simResultCreator: SimResultCreator): Observable<UserSimulationResultsDTO> {
        return this.backendService.post<UserSimulationResultsDTO>('/api/simulation-results/add', simResultCreator);
    }

    listResults(pageRequest: PageRequest, filter: SimResultsFilter | null): Observable<Pageable<UserSimulationResultsDTO>> {
        const params = this.createParams(pageRequest);

        return this.backendService.post<Pageable<UserSimulationResultsDTO>>('/api/simulation-results/', filter ? filter : {}, params);
    }

    createParams(pageRequest: PageRequest): HttpParams {
        let params = new HttpParams();
        if (pageRequest) {
            if (pageRequest.page !== undefined) {
                params = params.set('page', pageRequest.page.toString());
            }
            if (pageRequest.size !== undefined) {
                params = params.set('size', pageRequest.size.toString());
            }
            if (pageRequest.sort !== undefined && pageRequest.direction !== undefined) {
                params = params.set('sort', pageRequest.sort + ',' + pageRequest.direction);
            }
        }
        return params;
    }

}
