import {Injectable} from "@angular/core";
import {MultipleSlidesDialogComponent} from "../dialog-with-tabs/multiple-slides-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {HelperFileTypesEnum} from "../models/Util";

@Injectable({
    providedIn: 'root'
})

export class ExtraHelpDialogsService {


    constructor(
        private dialog: MatDialog,
    ) {
    }

    openDialog(type: HelperFileTypesEnum) {
        this.dialog.open(MultipleSlidesDialogComponent, {
            width: '70%',
            height: 'auto',
            data: {type: type},
        });
    }
}
