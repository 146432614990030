import { Component, OnInit, AfterViewInit, Input } from '@angular/core';

@Component({
    selector: 'app-loading-card-list',
    templateUrl: './loading-card-list.component.html'
})
export class LoadingCardListComponent implements OnInit, AfterViewInit {

    @Input() numberOfCards: Number = 5;
    constructor(
    ) {
    }
    ngAfterViewInit() {

    }
    ngOnInit() {

    }

}
