import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'login-error',
    templateUrl: './login.error.component.html',
    styleUrls: ['./login.error.component.css']
})
export class LoginErrorComponent implements OnInit {
    errorMessage: string | undefined;

    constructor(private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.errorMessage = params['message'] || 'An error occurred';
        });
    }
}
