import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FileReaderService {
  constructor(private http: HttpClient) {

  }

  readFile(file_name: string, responseType: any): Observable<any> {
    return this.http.get("assets/" + file_name, {responseType: responseType});
  }
}
