<div class="p-2 " >
  <div class="d-flex">
    <mat-icon>expand_more</mat-icon>
    <span><strong>{{ user.email }}</strong></span>
  </div>

  <div class="d-flex ms-4 m-3">
    <mat-icon>inbox</mat-icon>
    <span class="ms-2">Inbox</span>
  </div>
  <div class="d-flex ms-4 m-3">
    <mat-icon>edit_note</mat-icon>
    <span class="ms-2">Drafts</span>
  </div>
  <div class="d-flex ms-4 m-3">
    <img style="width: 24px !important; height: 24px !important;" src="assets/icons/{{userService.getThemeColor()}}/icons8-send.png">
    <span class="ms-2">Sent Items</span>
  </div>
  <div class="d-flex ms-4 m-3">
    <mat-icon style="margin-left: -24px">navigate_next</mat-icon>
    <img style="width: 24px !important; height: 24px !important;" src="assets/icons/{{userService.getThemeColor()}}/icons8-trash.svg">
    <span class="ms-2">Deleted Items</span>
    <span style="color: #469EF6;margin-left: auto">12</span>
  </div>
  <div class="d-flex ms-4 mt-1 m-3">
    <img style="width: 24px !important; height: 24px !important;" src="assets/icons/{{userService.getThemeColor()}}/icons8-spam.png">
    <span class="ms-2">Junk Email</span>
    <span style="margin-left: auto">20</span>
  </div>
  <div class="d-flex ms-4 m-3">
    <img style="width: 24px !important; height: 24px !important;" src="assets/icons/{{userService.getThemeColor()}}/icons8-box.svg">
    <span class="ms-2">Archive</span>
  </div>
  <div class="d-flex ms-4 m-3">
    <img style="width: 24px !important; height: 24px !important;" src="assets/icons/{{userService.getThemeColor()}}/icons8-note.png">
    <span class="ms-2">Notes</span>
  </div>
  <div class="d-flex ms-4 m-3">
    <img style="width: 24px !important; height: 24px !important;" src="assets/icons/{{userService.getThemeColor()}}/icons8-folder.svg">
    <span class="ms-2">Conversation History</span>
  </div>
  <div class="d-flex ms-4 m-3">
    <img style="width: 24px !important; height: 24px !important;" src="assets/icons/{{userService.getThemeColor()}}/icons8-folder.svg">
    <span class="ms-2">Quick Setup Settings</span>
  </div>
  <div class="d-flex ms-4 m-3">
    <mat-icon style="margin-left: -24px">navigate_next</mat-icon>
    <img style="width: 24px !important; height: 24px !important;" src="assets/icons/{{userService.getThemeColor()}}/icons8-folder.svg">
    <span class="ms-2">Sync Issues</span>
  </div>
  <div class="d-flex ms-4 m-3">
    <img style="width: 24px !important; height: 24px !important;" src="assets/icons/{{userService.getThemeColor()}}/icons8-search-folder.png">
    <span class="ms-2">Search Folders</span>
  </div>
  <div class="d-flex ms-4 m-3">
    <mat-icon style="margin-left: -24px">navigate_next</mat-icon>
    <img style="width: 24px !important; height: 24px !important;" src="assets/icons/{{userService.getThemeColor()}}/link-square.svg">
    <span class="ms-2">Shared with me</span>
  </div>
  <div class="d-flex ms-4 m-3">
    <mat-icon style="margin-left: -24px">navigate_next</mat-icon>
    <mat-icon>group</mat-icon>
    <span class="ms-2">Groups</span>
  </div>

  <div class="d-flex ms-4" style="color: #469EF6">
    <mat-icon>person_add_alt</mat-icon>
    <span class="ms-2">App account</span>
  </div>
</div>
