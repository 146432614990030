<h1 mat-dialog-title>
    {{data.title | uppercase}}
</h1>

<mat-dialog-content>
    <h5 *ngIf="!data.messageHtml">{{data.message}} </h5>

    <span *ngIf="data.messageHtml" [innerHTML]="data.message"></span>
    <p class="warning-text">{{data.note}}</p>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
    <button mat-button *ngIf="data.abort" (click)="onDismiss()">{{data.abort}}</button>
    <button mat-raised-button color="accent" *ngIf="data.reject" (click)="onReject()">
        <span class="d-flex justify-content-center align-items-center">
            <span>{{data.reject}}</span>
        </span>
    </button>
    <button mat-raised-button color="primary" (click)="onConfirm()">
        <span class="d-flex justify-content-center align-items-center">
            <span>{{data.confirm}}</span>
        </span>
    </button>
</mat-dialog-actions>
