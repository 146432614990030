import {Component} from '@angular/core';
import {UserService} from "../services/user.service";
import { UserDataStoreService } from '../shared/service/user-data-store.service';
import { UserDTO } from '../shared/model/user.model';

@Component({
  selector: 'app-mail-sidenav',
  templateUrl: './mail-sidenav.component.html',
  styleUrls: ['./mail-sidenav.component.css']
})
export class MailSidenavComponent {

  user!: UserDTO

  constructor(
    public userService: UserService,
    public userDataStoreService: UserDataStoreService
  ) {
    this.user = this.userDataStoreService.getUser()
  }
}
