import {Component, OnInit, Renderer2, RendererFactory2} from '@angular/core';
import {Router} from "@angular/router";
import {UserService} from "./services/user.service";
import {UserDataStoreService} from './shared/service/user-data-store.service';
import {environment} from 'src/environments/environment';
import {WebsocketService} from './core/websocket/websocket.service';
import {Theme} from "./shared/model/user.model";
import {Location} from '@angular/common';
import {AuthTokenService} from "./services/authtoken.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

    footerUrl = ''
    state: boolean | null = false;

    private renderer: Renderer2

    constructor(
        private router: Router,
        private userService: UserService,
        public userDataStoreService: UserDataStoreService,
        private factory2: RendererFactory2,
        private location: Location,
        private webSocketService: WebsocketService,
        private authTokenService: AuthTokenService
    ) {
        this.renderer = this.factory2.createRenderer(null, null)

        const currentPath = this.location.path();
        const user = this.userDataStoreService.getUser();
        if (user) {
            if (this.authTokenService.isAuthTokenPath(currentPath, "logout")) {
                this.authTokenService.processLogout();
            } else if(this.authTokenService.isAuthTokenPath(currentPath, "login")) {
                window.localStorage.removeItem("loggedInUser")
                this.authTokenService.processLogin();
                this.userService.setAllowLogout(false);
            } else {
                this.handleLoggedInUser(user);
            }
        } else {
            if (this.authTokenService.isAuthTokenPath(currentPath, "login")) {
                this.authTokenService.processLogin();
                this.userService.setAllowLogout(false);
            } else {
                this.router.navigate(['/login'], {replaceUrl: true});
            }
        }

        const prefTheme = this.userService.getPrefTheme() ?? Theme.LIGHT
        this.setTheme(prefTheme)
        this.userService.darkTheme.next(prefTheme == Theme.DARK)

        this.userService.darkTheme.subscribe(value => {
            this.setTheme(value ? Theme.DARK : Theme.LIGHT)
        })
        this.userService.simulationState.subscribe((state) => {
            this.state=state;
        })
    }

    private handleLoggedInUser(user: any) {
        this.userService.setAllowLogout(!user.isSSO);
    }


    ngOnInit(): void {
        this.webSocketService.initializeWebSocketConnection(environment.backendWebsocketURL, []);

        this.userService.hoveredUrl.subscribe(value => {
            this.footerUrl = value
        })
    }

    setTheme(theme: string) {
        this.renderer.removeClass(document.body, theme == Theme.DARK ? Theme.LIGHT : Theme.DARK)
        this.renderer.addClass(document.body, theme)
    }
}
