import {Injectable, Renderer2, RendererFactory2} from "@angular/core";
import {HttpServiceFacade} from "../http.service.facade";
import {
    EmailAttributes, EmailCreator,
    EmailDTO,
    EmailEditor,
    EmailFilter,
    EmailPhishingAttributesDTO,
    EmailStatusEditor
} from "src/app/models/PhishingAttributesModel";
import {Observable, map} from "rxjs";
import {environment} from "src/environments/environment";
import {IpcService} from "src/app/ipc-service";
import {FileReaderService} from "src/app/file-reader.service";
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest} from "@angular/common/http";
import {PhishingAttributesEnums} from "../../models/PhishingAttributesEnums";
import {Pageable, PageRequest} from "../model/pageable";
import * as moment from "moment/moment";
import {UserDTO} from "../model/user.model";


@Injectable({
    providedIn: 'root'
})
export class EmailService {

    EMAILS_DATA_FOLDER = "./assets/json/";
    private CUSTOM_URL_ATTRIBUTE = "customurl";
    private render: Renderer2;

    constructor(
        private backendService: HttpServiceFacade,
        private readonly _ipc: IpcService,
        private fileReader: FileReaderService,
        private https: HttpClient,
        private factory2: RendererFactory2,
    ) {
        this.render = this.factory2.createRenderer(null, null)

    }

    replaceEmailConstants(data: string, user: UserDTO, emailDomain: string) {
        data = data.replaceAll("[[firstName]]", user.firstName);
        data = data.replaceAll("[[lastName]]", user.lastName);
        data = data.replaceAll("[[email]]", user.email);
        data = data.replaceAll("[[userDomain]]", emailDomain)
        data = data.replaceAll("[[companyName]]", emailDomain.split(".")[0]);
        data = data.replaceAll("[[currentDate]]", moment().format("DD/MM/YYYY"));

        let pattern = /currentDate\+(\d+)/g;
        let match;
        while ((match = pattern.exec(data)) !== null) {
            let number_of_days = parseInt(match[1], 10);
            data = data.replaceAll("[[currentDate+" + number_of_days + "]]", moment().add(number_of_days, "days").format("DD/MM/YYYY"));

        }

        pattern = /currentDate\-(\d+)/g;
        while ((match = pattern.exec(data)) !== null) {
            let number_of_days = parseInt(match[1], 10);
            data = data.replaceAll("[[currentDate-" + number_of_days + "]]", moment().subtract(number_of_days, "days").format("DD/MM/YYYY"));

        }

        return data;
    }

    appendHoverStyle(doc: Document) {
        const styleElement = this.render.createElement('style');
        const styleContent = `a:hover {cursor: pointer !important}`;
        this.render.appendChild(styleElement, this.render.createText(styleContent));
        this.render.appendChild(doc.head, styleElement);

        return doc;
    }


    editDisplayURLS(htmlContent: string, linkDisplayURL?: string) {
        const regexHREF = /href="([^\'\"]+)/g;
        const regexCustomURL = /customurl="([^\'\"]+)/g;

        const processRegex = (regex: RegExp) => {
            const regex_values = htmlContent.match(regex);
            if (regex_values) {
                regex_values.forEach(value => {
                    htmlContent = this.replaceUrl(htmlContent, regex === regexHREF ? 'href' : 'customurl', value, linkDisplayURL);
                });
                return true; // Return true if the regex matched
            }
            return false; // Return false if the regex didn't match
        };

        processRegex(regexHREF) || processRegex(regexCustomURL);

        return htmlContent;
    }

    replaceUrl(htmlContent: string, key: string, value: string, linkDisplayURL?: string){
        let attributeKeyValue = value.split(`${key}=`)
        let urlValue = attributeKeyValue[1]

        if (linkDisplayURL)
            urlValue = linkDisplayURL

        if (urlValue.startsWith('"'))
            urlValue = urlValue.substring(1)

        let newU = this.CUSTOM_URL_ATTRIBUTE + '="' + urlValue

        htmlContent = htmlContent.replaceAll(value, newU);

        return htmlContent
    }

    isWrongAnswer(email: EmailDTO, type: PhishingAttributesEnums) {
        if (email?.scores)
            switch (type) {
                case PhishingAttributesEnums.SENDER:
                    return email?.emailPhishingAttributes?.isSenderPhishy == email.scores.get(PhishingAttributesEnums.SENDER) ?
                        'assets/icons/wrong.svg' : 'assets/icons/check.svg'
                case PhishingAttributesEnums.CONTENT:
                    return email?.emailPhishingAttributes?.isContentPhishy == email.scores.get(PhishingAttributesEnums.CONTENT) ?
                        'assets/icons/wrong.svg' : 'assets/icons/check.svg'
                case PhishingAttributesEnums.LINKS:
                    return email?.emailPhishingAttributes?.isLinkPhishy == email.scores.get(PhishingAttributesEnums.LINKS) ?
                        'assets/icons/wrong.svg' : 'assets/icons/check.svg'
                case PhishingAttributesEnums.ATTACHMENTS:
                    return email?.emailPhishingAttributes?.isAttachmentPhishy == email.scores.get(PhishingAttributesEnums.ATTACHMENTS) ?
                        'assets/icons/wrong.svg' : 'assets/icons/check.svg'
                default:
                    return null;
            }
        else
            return null;
    }

    async listEmails(pageRequest: PageRequest, filter?: EmailFilter): Promise<Pageable<EmailDTO>> {
        if (environment.loadDataFromBE) {
            const params = this.createParams(pageRequest!);

            return new Promise((resolve, reject) => {
                this.backendService.post<Pageable<EmailDTO>>('/api/emails/', filter ?? {}, params)
                    .subscribe(data => resolve(data));
            });
        } else {
            this._ipc?.send("listDir", this.EMAILS_DATA_FOLDER);
            return new Promise((resolve, reject) => {
                this._ipc?.on('dirListData', async (_event: any, data) => {
                    if (data.error) {
                        console.error('Error listing directory:', data.error);
                        resolve(new Pageable<EmailDTO>());
                    } else {
                        let emails = await this.processFiles(data.files);
                        const temp = new Pageable<EmailDTO>()
                        temp.content = emails;
                        temp.numberOfElements = emails.length;
                        temp.totalElements = emails.length;
                        resolve(temp)
                    }
                });
            })
        }
    }

    async listUserEmails(emailCount: number | undefined, filter?: EmailFilter): Promise<EmailDTO[]> {
        if (environment.loadDataFromBE) {
            return new Promise((resolve, reject) => {
                this.backendService.post<EmailDTO[]>('/api/emails/user',
                    {
                        emailCount: emailCount,
                        filter: filter ?? {}
                    })
                    .subscribe(data => resolve(data));
            });
        } else {
            this._ipc?.send("listDir", this.EMAILS_DATA_FOLDER);
            return new Promise((resolve, reject) => {
                this._ipc?.on('dirListData', async (_event: any, data) => {
                    if (data.error) {
                        console.error('Error listing directory:', data.error);
                        resolve([]);
                    } else {
                        let emails = await this.processFiles(data.files);
                        resolve(emails)
                    }
                });
            })
        }
    }

    processFiles = async (files: string[]): Promise<EmailDTO[]> => {
        let emails: EmailDTO[] = [];
        files.forEach(async (file, index) => {
            const email = await this.getFile(file, index);
            emails.push(email);
        });
        return emails;
    }

    getFile = async (fileName: string, currentEmailAttributesId: number): Promise<EmailDTO> => {
        return new Promise((resolve, reject) => {
            this.fileReader.readFile(`json/${fileName}`, 'json')
                .subscribe((data) => {
                    let email = data as EmailAttributes;
                    let emailPhishingAttributes: EmailPhishingAttributesDTO = {
                        emailID: currentEmailAttributesId,
                        isSenderPhishy: email.phishing_attributes.sender,
                        isContentPhishy: email.phishing_attributes.content,
                        isLinkPhishy: email.phishing_attributes.links,
                        isAttachmentPhishy: email.phishing_attributes.attachments,
                        isQRCodePhishy: email.phishing_attributes.qrCode,
                        attachments: email.email.attachments,
                        linkDisplayURL: email.phishing_attributes.link_display_url,
                        qRCodeURL: ""
                    };
                    let emailDTO: EmailDTO = {
                        id: currentEmailAttributesId,
                        description: email.email.scenario,
                        subject: email.email.subject,
                        senderDisplayName: email.email.sender_display_name,
                        senderAddress: email.email.sender_address,
                        legit: email.email.is_legit,
                        emailPhishingAttributes,
                        isSelected: false,
                        fileName: fileName.split(".")[0],
                        user_judgment_is_legit: undefined,
                        active: true,
                        isAnsweredPhishy: undefined,
                        categories: [
                            {
                                id: 1,
                                description: 'Entertainment'
                            },
                            {
                                id: 2,
                                description: 'Social Media'
                            }
                        ]
                    }
                    resolve(emailDTO);
                });
        });
    }

    getEmail(emailId: number) {
        return this.backendService.get<EmailDTO>(`/api/emails/${emailId}`);
    }

    findEmail(filter: EmailFilter) {
        return this.backendService.post<EmailDTO>(`/api/emails/body-url`, filter);
    }

    getPhishingAttributes(emailId: number) {
        return this.backendService.get<EmailPhishingAttributesDTO>(`/api/emails/${emailId}/attributes`);
    }

    getEmailBody(emailId: number, readStaging: boolean, fileName?: string) {
        if (environment.loadDataFromBE)
            return this.backendService.downloadFile('/api' + ((readStaging) ? '/staging' : '') + `/emails/${emailId}/body`).pipe(
                map((res: any) => {
                    return new Blob([res.body], {type: 'application/html'});
                }));
        else {
            return this.fileReader.readFile("html/" + fileName + ".html", 'text').pipe(
                map((res: any) => {
                    return new Blob([res], {type: 'application/html'});
                }));
        }
    }

    uploadEmails(files: File[], overwrite: boolean, userId: number): Observable<HttpEvent<{}>> {
        const formData: FormData = new FormData();
        formData.append('overwrite', overwrite.toString());
        formData.append('userId', userId.toString());
        for (let i = 0; i < files.length; i++) {
            if (files[i]) {
                formData.append('files', files[i]);
            }
        }
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');

        return this.backendService.postWithOptions<any>(
            '/api/staging/emails/upload',
            formData,
            {
                reportProgress: true,
                observe: 'events',
                headers,
            }
        );
    }

    createEmail(file: File, inp: EmailCreator): Observable<HttpEvent<{}>> {
        const formData = this.getFormData(file, inp);

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');

        return this.backendService.postWithOptions<any>(
            '/api/emails/create',
            formData,
            {
                reportProgress: true,
                observe: 'events',
                headers,
            }
        );
    }
    getFormData(file: File | undefined, inp: any) {
        const formData: FormData = new FormData();
        if(file)
            formData.append("file", new Blob([file], {type: file.type}), file.name);

        if(inp.id)
            formData.append('id', inp.id.toString());

        formData.append('subject', inp.subject?.toString());
        formData.append('description', inp.description?.toString());
        formData.append('senderDisplayName', inp.senderDisplayName?.toString());
        formData.append('senderAddress', inp.senderAddress?.toString());
        formData.append('isLegit', inp.isLegit.toString());
        formData.append('fileName', inp.fileName?.toString());
        formData.append('categories', inp.categories.toString());
        formData.append('isSenderPhishy', inp.isSenderPhishy.toString());
        formData.append('isContentPhishy', inp.isContentPhishy.toString());

        if (inp.isLinkPhishy != null)
            formData.append('isLinkPhishy', inp.isLinkPhishy.toString());

        if (inp.isAttachmentPhishy != null)
            formData.append('isAttachmentPhishy', inp.isAttachmentPhishy.toString());

        if (inp.attachments != null)
            formData.append('attachments', inp.attachments.toString());

        if (inp.linkDisplayUrl != null)
            formData.append('linkDisplayUrl', inp.linkDisplayUrl.toString());

        return formData;
    }

    createParams(pageRequest: PageRequest): HttpParams {
        let params = new HttpParams();
        if (pageRequest) {
            if (pageRequest.page !== undefined) {
                params = params.set('page', pageRequest.page.toString());
            }
            if (pageRequest.size !== undefined) {
                params = params.set('size', pageRequest.size.toString());
            }
            if (pageRequest.sort !== undefined && pageRequest.direction !== undefined) {
                params = params.set('sort', pageRequest.sort + ',' + pageRequest.direction);
            }
        }
        return params;
    }

    listStagingEmails(pageRequest: PageRequest): Observable<Pageable<EmailDTO>> {
        let params = new HttpParams();
        if (pageRequest.page !== undefined) {
            params = params.set('page', pageRequest.page.toString());
        }
        if (pageRequest.size !== undefined) {
            params = params.set('size', pageRequest.size.toString());
        }
        if (pageRequest.sort !== undefined && pageRequest.direction !== undefined) {
            params = params.set(
                'sort',
                pageRequest.sort + ',' + pageRequest.direction
            );
        }
        return this.backendService.get<Pageable<EmailDTO>>('/api/staging/emails/', params);
    }

    updateTemplate(emailID: number, file: File) {
        const formData: FormData = new FormData();
        formData.append('file', file);
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');

        return this.backendService.post<any>(
            `/api/staging/emails/${emailID}/body`,
            formData);
    }

    approveStagingEmail(emailID: number) {
        return this.backendService.get<void>(`/api/staging/emails/${emailID}/approve`);
    }

    updateEmail(input: EmailEditor, file?: File): Observable<HttpEvent<{}>> {
        const formData = this.getFormData(file, input);

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');

        return this.backendService.postWithOptions<EmailDTO>(
            '/api/emails/update',
            formData,
            {
                reportProgress: true,
                observe: 'events',
                headers,
            }
        );
    }

    updateEmailStatus(input: EmailStatusEditor): Observable<EmailDTO> {
        return this.backendService.put<EmailDTO>(`/api/emails/update/status`, input);
    }
}
