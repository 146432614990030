import {Component} from '@angular/core';
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {UserService} from "../services/user.service";
import {CustomersService} from "../services/customers.service";
import {PageRequest} from "../shared/model/pageable";
import {CustomerDTO} from "../shared/model/customer.model";
import {UserRole} from "../shared/model/user-role.model";
import {AlertPopupService} from "../services/alert.popup.service";
import {finalize, Subject, takeUntil} from "rxjs";
import {BaseForm} from "../shared/base-form";
import {HttpServiceFacade} from "../shared/http.service.facade";
import {ValidatorsService} from "../shared/service/validator.service";
import {FieldErrorDTO} from "../shared/model/serviceResponse.model";

@Component({
    selector: 'app-login',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.css']
})
export class SignupComponent extends BaseForm {
    firstName!: FormControl;
    lastName!: FormControl;
    email!: FormControl;
    password!: FormControl;
    confirmPassword!: FormControl;
    customerId!: FormControl;

    hide_pass = true;
    hide_confirm = true;

    isLoadingCustomers = true;
    isLoadingRoles = true;
    isSubmitting = false;

    customers!: CustomerDTO[];
    selectedCustomerId!: number;

    roles!: UserRole[];

    private ngUnsubscribe: Subject<void> = new Subject();


    constructor(
        private formBuilder: FormBuilder,
        private validatorsService: ValidatorsService,
        private router: Router,
        private userService: UserService,
        private customerService: CustomersService,
        protected alertPopupService_: AlertPopupService,
        httpServiceFacade: HttpServiceFacade
    ) {
        super(httpServiceFacade, alertPopupService_);
    }

    private initForm() {
        this.firstName = this.formBuilder.control('', Validators.compose([Validators.required]));
        this.lastName = this.formBuilder.control('', Validators.compose([Validators.required]));
        this.email = this.formBuilder.control('', Validators.compose([Validators.required, Validators.email, Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")]));
        this.confirmPassword = this.formBuilder.control('', Validators.compose([Validators.required,
            this.validatorsService.areEqual('confirmPassword', 'password')]));
        this.customerId = this.formBuilder.control('', Validators.compose([Validators.required]));

        this.password = this.formBuilder.control('', Validators.compose([
                // Password Field is Required
                Validators.required,
                // check whether the entered password has a number
                this.validatorsService.patternValidator(/\d/, {hasNumber: true}),
                // check whether the entered password has upper case letter
                this.validatorsService.patternValidator(/[A-Z]/, {hasCapitalCase: true}),
                // check whether the entered password has a lower-case letter
                this.validatorsService.patternValidator(/[a-z]/, {hasSmallCase: true}),
                // check whether the entered password has a special character
                this.validatorsService.patternValidator(/[!"#$%&'()*+,-./:;<=>?@\\\^_`{|}~¡¢£¤¥¦§¨©ª«¬­®¯°±²³´µ¶·¸¹º»¼½¾¿×÷―‗‘’‚‛“”„†‡•…‰′″‹›‼‾⁄⁊₠₡₢₣₤₥₦₧₨₩₪₫€₭₮₯₰₱₲₳₴₵₶₷₸₹₺₻₼₽₾]/, {hasSpecialCharacters: true}),
                // check whether the entered password hasn't a whitespace character
                this.validatorsService.patternValidator(/^\S*$/, {hasNoWhiteSpace: true}),
                // Has a minimum length of 10 characters
                Validators.minLength(10),
                // Has a maximum length of 30 characters
                Validators.maxLength(30),
                this.validatorsService.areEqual('confirmPassword', 'password')
            ])
        );

        this.validationRules = [
            {key: 'required', field: 'password', message: 'Password is required.'},
            {key: 'minlength', field: 'password', message: 'Password must be at least 10 characters.'},
            {key: 'hasNumber', field: 'password', message: 'Password must have at least 1 number.'},
            {key: 'hasCapitalCase', field: 'password', message: 'Password must contain at least 1 uppercase letter.'},
            {key: 'hasSmallCase', field: 'password', message: 'Password must contain at least 1 lowercase letter.'},
            {
                key: 'hasSpecialCharacters',
                field: 'password',
                message: 'Password must contain at least 1 Special Character.'
            },
            {key: 'hasNoWhiteSpace', field: 'password', message: 'Password may not contain any spaces.'},
            {key: 'required', field: 'firstName', message: 'First name is required.'},
            {key: 'required', field: 'lastName', message: 'Last name is required.'},
            {key: 'required', field: 'confirmPassword', message: 'Password confirmation is required.'},
            {key: 'areEqual', field: 'confirmPassword', message: 'Password confirmation failed.'},
            {key: 'required', field: 'email', message: 'Email is required.'},
            {key: 'email', field: 'email', message: 'Email is invalid.'},
            {key: 'alreadyExists', field: 'email', message: 'Email already exists.'}
        ];

        this.controlGroup = this.formBuilder.group({
            email: this.email,
            firstName: this.firstName,
            lastName: this.lastName,
            password: this.password,
            confirmPassword: this.confirmPassword,
            customerId: this.customerId,
        });
    }

    override ngOnInit() {
        this.initForm();
        this.loadCustomers();
    }

    private loadCustomers() {
        this.customerService.getCustomers(new PageRequest(0, 1000)).subscribe((data) => {
                this.customers = data.content!;
                this.isLoadingCustomers = false;
            },
            (error) => {
                this.alertPopupService.error(error, "Error fetching customers")
                this.customers = [];
                this.isLoadingCustomers = false;
            })
    }

    signUp() {
        this.isSubmitting = true;
        this.userService.register({...this.controlGroup.value})
            .pipe(
                finalize(() => this.isSubmitting = false),
                takeUntil(this.ngUnsubscribe))
            .subscribe(
                data => {
                    this.alertPopupService.success('Your registration is complete, please login.', 'Registration Completed');
                    this.router.navigate(['/login']);
                },
                err => {

                    if (err.validation && err.validation.errors) {
                        err.validation.errors.forEach((element: FieldErrorDTO) => {
                            this.alertPopupService.error(element.message, "Failed to register user");
                            if(element.field == 'email')
                                this.email.setErrors({alreadyExists: true});
                        });
                    }
                    else
                        this.alertPopupService.error("Could not register user");
                }
            );
    }

    override ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
