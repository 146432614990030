
import { share, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams, HttpResponse, HttpClient, HttpEvent } from '@angular/common/http';


export const API_VERSION = 'v1.0';


// This static constants class is used to define custom options to control the behaviour of the facade
export class HttpServiceFacadeOptions {
    // if this set to true a given request won't notify the components/services that has subscribed to the response.
    // This is usefull when,  a service does it's own error handling and doesn't want the httpFacade to get in the way.
    public static readonly INTROVERT: string = 'INTROVERT';
}

// This class is a facade on the angular http implementation
// This is being used to enable the ability to subscribe to all the responses of outgoing requests
@Injectable()
export class HttpServiceFacade {

    onResponse$: Observable<Observable<HttpResponse<any>>>;
    private onResponseObserver: any;

    onDataResponse$: Observable<Observable<HttpResponse<any>>>;
    private onDataResponseObserver: any;


    constructor(private http: HttpClient) {
        this.onResponse$ = (((new Observable(observer => this.onResponseObserver = observer)
            .pipe(share())) as any) as Observable<Observable<HttpResponse<any>>>);
        this.onDataResponse$ = (((new Observable(observer => this.onDataResponseObserver = observer)
            .pipe(share())) as any) as Observable<Observable<HttpResponse<any>>>);
    }



    get<T>(url: string, paramters?: HttpParams, facadeOptions: Object = {}) {
        const response = this.http.get<T>(url, { params: paramters }).pipe(share());
        if (!(<any>facadeOptions)[HttpServiceFacadeOptions.INTROVERT]) {
            this.notify((response) as any);
        }
        return response;
    }

    // in some cases we want to be able to react to data loading
    // blocking UI input controls for server while the data is fetched for an update
    getData<T>(url: string, paramters?: HttpParams) {
        const response = this.get<T>(url, paramters);
        this.notifyData((response) as any);
        return response;
    }

    downloadFile(url: string, paramters?: HttpParams): Observable<HttpResponse<any>> {
        return this.http.get(url, { responseType: 'blob', params: paramters, observe: 'response' })
            .pipe(share());
    }

    downloadFilePost(url: string, body: any, paramters?: HttpParams): Observable<HttpResponse<any>> {
        return this.http.post(url, body, { responseType: 'blob', params: paramters, observe: 'response' })
            .pipe(share());
    }

    //Use this post to get a response constists of headers & body (default header)
    postResponse<T>(url: string, body: any, paramters?: HttpParams): Observable<HttpResponse<T>> {
        const response = this.http.post<T>(url, body, { params: paramters, observe: 'response' }).pipe(share());
        this.notify((response) as any);
        return response;
    }

    //Use this post to get a response constists of only body
    post<T>(url: string, body?: any, paramters?: HttpParams): Observable<T> {
        const response = this.postResponse<T>(url, body, paramters).pipe(share());
        this.notify((response) as any);
        return response.pipe(
            map(res => res.body!)
        );
    }
    postWithOptions<T>(url: string, body: any, options?: any): Observable<HttpEvent<T>> {
        const response = this.http.post<T>(url, body, options).pipe(share());
        this.notify((response) as any);
        return response;
    }
    put<T>(url: string, body: any, paramters?: HttpParams) {
        this.http.post('x', {}, { reportProgress: true, })
        const response = this.http.put<T>(url, body, { params: paramters }).pipe(share());
        this.notify((response) as any);
        return response;
    }

    delete<T>(url: string, paramters?: HttpParams) {
        const response = this.http.delete<T>(url, { params: paramters }).pipe(share());
        this.notify((response) as any);
        return response;
    }

    patch<T>(url: string, body: any, paramters?: HttpParams) {
        const response = this.http.patch<T>(url, body, { params: paramters }).pipe(share());
        this.notify((response) as any);
        return response;
    }

    head<T>(url: string, paramters?: HttpParams) {
        const response = this.http.head<T>(url, { params: paramters }).pipe(share());
        this.notify((response) as any);
        return response;
    }

    private notify(response: Observable<HttpResponse<any>>) {
        if (this.onResponseObserver) {
            this.onResponseObserver.next(response);
        }
    }

    private notifyData(response: Observable<HttpResponse<any>>) {
        if (this.onDataResponseObserver) {
            this.onDataResponseObserver.next(response);
        }
    }
}
