<div style="height: 100%">

    <div class="d-flex" style="height: 91%">

        <!--  Side Navigation  -->
        <div class="d-flex flex-column" style="background-color: var(--main-bg-color); opacity: 0.35">
            <div style="border-left-style: solid; border-left-color: #469EF6; border-left-width: 2px">
                <mat-icon class="m-3" style="color: var(--button-bg-color)">email</mat-icon>
            </div>
            <img class="m-3" src="assets/icons/To-Do_icon.png" style="width: 28px; height: 20px">
            <img class="m-3" src="assets/icons/onedrive.png" style="width: 28px; height: 20px">
        </div>
        <!--  End Side Navigation  -->

        <!--  Main Body  -->
        <div style="height: 100%; width: 100%;">
            <app-mail-toolbar [ngStyle]="{'width': state ? '78%': '', 'display': state ? 'block': '',
            'opacity':'0.35'}"></app-mail-toolbar>

            <mat-drawer-container autosize class="w-100" style="height: 91%">
                <mat-drawer #drawer class="col-2" mode="side">
                    <app-mail-sidenav [ngStyle]="{'opacity':'0.35'}"></app-mail-sidenav>
                </mat-drawer>

                <mat-drawer-content style="height: 100%; overflow: hidden !important;">
                    <app-main></app-main>
                </mat-drawer-content>
            </mat-drawer-container>
        </div>
    </div>
</div>
