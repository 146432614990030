<mat-table [dataSource]="dataSource">

    <ng-container *ngFor="let header of columnsHeader" [matColumnDef]="header">
        <mat-header-cell class="ps-3" mat-header-cell *matHeaderCellDef disableClear>
            {{header}}
        </mat-header-cell>
        <mat-cell class="ps-3" mat-cell *matCellDef="let row">
            <div class="lines ps-3 pe-3">
                <h3></h3>
            </div>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="columnsHeader; sticky: true" class="mat-elevation-z4">
    </mat-header-row>
    <mat-row *matRowDef="let row; columns: columnsHeader;" class="mat-elevation-z4">
    </mat-row>
</mat-table>