import { Injectable } from '@angular/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';


@Injectable({
    providedIn: 'root'
})
export class AlertPopupService {

    // default messaging options
    options = {
        'closeButton': false,
        'progressBar': false,
        'positionClass': 'toast-bottom-right',
        'timeOut': '5000',
        'extendedTimeOut': '3000',
        'newestOnTop': true
    };

    constructor(private toastr: ToastrService) { }

    success(text: string, title?: string, options?: any): void {
        if (text || title) {
            this.toastr.success(text, title, { ...this.options, ...options });
        }
    }

    info(text: string, title?: string, options?: any): void {
        if (text || title) {
            this.toastr.info(text, title, { ...this.options, ...options });
        }
    }

    warning(text: string, title?: string, options?: any): void {
        if (text || title) {
            this.toastr.warning(text, title, { ...this.options, ...options });
        }
    }

    error(text: string, title?: string, options?: any): ActiveToast<any> | undefined {
        if (text || title) {
            return this.toastr.error(text, title, { ...this.options, ...options });
        }
        return undefined;
    }

    clear(): void {
        this.toastr.clear();
    }

}
