import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {ConfirmationModel} from "../../model/generic-confirmation.model";
import {GenericConfirmationDialog} from "../generic-confirmation/generic-confirmation.dialog";
import {MatDialog} from "@angular/material/dialog";
import {EmailService} from "../../service/email.service";
import {UserDataStoreService} from "../../service/user-data-store.service";
import {UserDTO} from "../../model/user.model";
import {UserService} from "../../../services/user.service";

@Component({
    selector: 'app-html-editor',
    templateUrl: './html-editor.component.html',
    styleUrls: ['./html-editor.component.css']
})
export class HtmlEditorComponent implements OnChanges{
    @Input() htmlContent: string = "";
    @Input() linksPhishy?: boolean = false;
    @Input() displayLinkUrl?: string = "";
    @Input() wrapperHeight!: string;
    @Output() changeEvent: EventEmitter<string> = new EventEmitter<string>();
    @Input() showExpandBtn: boolean = false;
    @Input() expandViewFn!: () => void;

    private CUSTOM_URL_ATTRIBUTE = "customurl";
    doc!: Document
    options = { theme: 'vs', language: 'html', automaticLayout: true };
    isExpanded: boolean = false;
    parsedHtmlContent: string = "";
    loggedInUser: UserDTO;

    inPreview: boolean = false;

    constructor(
        private dialog: MatDialog,
        private emailService: EmailService,
        private userDataStoreService: UserDataStoreService,
        private userService: UserService,
    ) {

        this.loggedInUser = this.userDataStoreService.getUser();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["htmlContent"]?.currentValue) {
            this.htmlContent = changes["htmlContent"].currentValue
            this.parsedHtmlContent = this.htmlContent;

            this.handleLinks()
        }

        if(changes["displayLinkUrl"]?.currentValue)
            this.handleLinks()


        if(changes["linksPhishy"]) {
            if(!changes["linksPhishy"]?.currentValue) {
                this.parsedHtmlContent = this.htmlContent
                this.handleLinks(false)
            }else
                this.handleLinks()
        }
    }

    /**
     * showDisplayLinkUrl  handle to replace the href values with the displayLinkURL or not
     * @param showDisplayLinkUrl
     */
    handleLinks(showDisplayLinkUrl: boolean = true){
        const parser = new DOMParser();

        this.doc = parser.parseFromString(this.parsedHtmlContent, 'text/html');
        this.doc = this.emailService.appendHoverStyle(this.doc)

        const serializer = new XMLSerializer();
        this.parsedHtmlContent = serializer.serializeToString(this.doc);

        this.parsedHtmlContent = this.emailService.editDisplayURLS(this.parsedHtmlContent, showDisplayLinkUrl ? this.displayLinkUrl : undefined );
    }

    onExpandClick(){
        this.isExpanded = !this.isExpanded
        this.expandViewFn();
    }

    onPreviewClick(){
        this.inPreview = !this.inPreview

        if(this.inPreview) {
            let emailDomain = this.loggedInUser.email.split("@")[1]
            this.parsedHtmlContent = this.emailService.replaceEmailConstants(this.htmlContent, this.loggedInUser, emailDomain)
        }
        else
            this.parsedHtmlContent = this.htmlContent;

        this.handleLinks(this.linksPhishy)
    }

    valueChange() {
        this.changeEvent.emit(this.htmlContent)
    }

    viewConstants(){
        let list = ""
        list += "<li> [[userDomain]] - email domain of the user. ex: gmail.com </li>"
        list += "<li> [[firstName]] </li>"
        list += "<li> [[lastName]] </li>"
        list += "<li> [[email]] </li>"
        list += "<li> [[companyName]] </li>"
        list += "<li> [[currentDate]] </li>"
        list += "<li> [[currentDate+{days}]] e.x: [[currentDate+2]] </li>"
        list += "<li> [[currentDate-{days}]] e.x: [[currentDate-5]] </li>"
        list += "</ul>"

        const confirmationModel: ConfirmationModel = new ConfirmationModel(
            "Available Constants",
            `${list}`,
            ``,
            "Close",
            "",
            "",
            true
        );

        const dialogRef = this.dialog.open(GenericConfirmationDialog, {
            width: '40%',
            data: { ...confirmationModel }
        });
    }

    onClick(event: any) {
        event.preventDefault()
    }


    onHover(event: any) {
        const href = event.target.getAttribute(this.CUSTOM_URL_ATTRIBUTE);

        if (href)
            this.userService.hoveredUrl.next(href)

        else
            this.userService.hoveredUrl.next("")
    }
}

