<div class="row pt-2 h-100" style="margin: 0!important">
  <div class="h-100" style="width: 20%">
    <!-- Here Inbox component -->
    <app-email-listing *ngIf="emails" #appEmailListing (emailSelectedEmitter)="emailSelected($event)"
                       [isLoading]="isLoadingEmails" [emails]="emails" [disableScroll]="disableScroll"
                       [disableSelection]="!simulationStarted || simulationFinished"></app-email-listing>
  </div>

  <div class="h-100 row" style="width: 80%">

    <div [ngStyle]="{'width': simulationFinished ? '100%' : simulationStarted ? '75%' : '' }"
         class="ps-0" style="height: 100%">

      <div *ngIf="!currentEmail || simulationFinished" class="mb-2 d-flex align-items-center br-5" style="height: 7%; background-color: var(--container-background)">
        <span class="p-3" style="font-size: 16px;"> <Strong>{{ message }}</Strong> </span>
      </div>
      <!-- Here will be Welcome component OR Mail component -->
      <app-welcome *ngIf="!simulationStarted && !simulationFinished" #appWelcome [isLoadingEmails]="isLoadingEmails"></app-welcome>

      <app-view-email *ngIf="simulationStarted  && !simulationFinished" [email] = "currentEmail!"></app-view-email>

      <app-final-score *ngIf="simulationFinished"
                       [emailAttributes]="emails"
                       [simulationStartTime]="simulationStartTime"
                       [simulationEndTime]="simulationEndTime">
      </app-final-score>

    </div>
    <div [ngStyle]="{'display': currentEmail && !simulationFinished ? 'block': 'none'}" 
    style="width: 20.5%; height: 100%; padding: 0 !important;position: fixed;right: 0;top: 0;">
      <!-- Here will be Questionnaire component -->
      <app-scoring-panel #appScoringPanel [email]="currentEmail!" [lastEmail]="last_email" [immediateFeedback]="immediateFeedback"></app-scoring-panel>
    </div>
  </div>
</div>
