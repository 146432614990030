import {Injectable} from '@angular/core';
import {EmailClientsEnum, UserDTO} from '../model/user.model';
import {RolesEnum} from "../model/user-role.model";
import {CustomerDTO} from "../model/customer.model";
import {Observable, Subscriber} from "rxjs";
import {share} from "rxjs/operators";
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class UserDataStoreService {

    customer: CustomerDTO | undefined;

    private onCustomerChangeObserver!: Subscriber<CustomerDTO>;
    customerChange$: Observable<CustomerDTO>;

    constructor() {
        this.customerChange$ = (((new Observable<CustomerDTO>(observer => this.onCustomerChangeObserver = observer)
            .pipe(share()))));
    }

    public getUser(): UserDTO {
        return JSON.parse(window.localStorage.getItem('loggedInUser')!) as UserDTO;
    }

    loadUser(user: UserDTO) {
        if (!user) {
            return;
        }
        this.saveUser(user);
    }

    private saveUser(user: UserDTO) {
        window.localStorage.setItem('loggedInUser', JSON.stringify(user));
    }

    getUserEmailClient() {
        return (this.getUser()) ?
            this.getUser().emailClient : EmailClientsEnum.OUTLOOK;
    }

    hasAdminRole() {
        const adminRoles = [RolesEnum.ADMIN, RolesEnum.COMPANY_ADMIN, RolesEnum.SUPER_ADMIN];
        return this.getUser() &&  adminRoles.includes(this.getUser().roleID);
    }

    isAdmin() {
        return this.getUser()?.roleID == RolesEnum.ADMIN
    }

    isSuperAdmin() {
        return this.getUser()?.roleID == RolesEnum.SUPER_ADMIN
    }

    isCompanyAdmin() {
        return this.getUser()?.roleID == RolesEnum.COMPANY_ADMIN
    }

    isUser() {
        return this.getUser()?.roleID == RolesEnum.USER
    }

    setCustomer(customer: CustomerDTO) {
        this.customer = customer;
        this.onCustomerChangeObserver?.next(this.customer);
    }

    isEXEMode(){
        return !environment.loadDataFromBE;
    }

}
