import {Injectable} from '@angular/core';

import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  public stompClient: any;

  private subscriptions: any[] = [];

  public messageReceived: Subject<string> = new Subject();

  constructor() {

  }

  initializeWebSocketConnection(serverUrl: string, channels: string[]) {
    let webSocket = new SockJS(serverUrl);
    this.stompClient = Stomp.over(webSocket);
    if (null == this.stompClient) {
      console.log("Failed to initialize StompClient.")
      return;
    }

    this.stompClient.reconnect_delay = 5000;
    const connect_callback = (frame: any) => {
      channels.forEach(channel => {
        this.subscribe(channel);
      })
    };

    const error_callback = (error: any) => {
      console.log(error);
    };

    let headers = {};
    this.stompClient.connect(headers, connect_callback, error_callback);
  }

  disconnect() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });

    this.subscriptions = [];

    if(null != this.stompClient)
      this.stompClient.disconnect(() => {});
  }

  subscribe(channel: string, subscribeId? : string) {
    let oldSubscription;
    if(subscribeId)
      oldSubscription = this.subscriptions.find(sub => sub.id == subscribeId);

    if(oldSubscription)
      return;

    let subscription = this.stompClient.subscribe(channel, (message: any) => {
      if (message.body) {
        this.messageReceived.next(message.body);
      } else {
        console.log("Received empty message body from websocket");
      }
    }, {id: subscribeId});

    this.subscriptions.push(subscription);
  }

  unsubscribeById(id: string){
    this.stompClient.unsubscribe(id);
    this.subscriptions = this.subscriptions.filter( el => el.id !== id ); 
  }
}
