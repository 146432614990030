export class Pageable<T> {
    content?: Array<T> = new Array<T>();
    totalElements? = -1;
    numberOfElements? = -1;
    totalPages? = -1
}

export class PageRequest {
    page: number;
    size: number;
    sort?: string;
    direction?: string;

    constructor(pageIndex: number, pageSize: number, sortCol?: string, dir?: string) {
        this.page = pageIndex;
        this.size = pageSize;
        this.sort = sortCol;
        this.direction = dir;
    }
}
