import { Component, OnInit, AfterViewInit, Input } from '@angular/core';

@Component({
    selector: 'app-loading-table',
    templateUrl: './loading-table.component.html',
    styleUrls: ['./loading-table.component.scss']
})
export class LoadingTableComponent implements OnInit, AfterViewInit {

    @Input() numberOfRows = 5;
    @Input() columnsHeader: Array<string> = ['a', 'b'];
    dataSource: Array<{}>;
    constructor(
    ) {
        this.dataSource = [].constructor(this.numberOfRows);
    }
    ngAfterViewInit() {

    }
    ngOnInit() {

    }

}
