export enum HintToolTips {
  SENDER = "When determining if a s ender is phishy be on the lookout for misspellings in the sender’s domain such as app1e or micr0soft, do a quick google search of the sender’s email address and verify it actually came from the service it claims to be.",
  CONTENT = "When determining if the content of an email is suspecious look for things like misspellings, variable placeholders, and context. Is this email for a service you subscribe to? Have you seen other emails similar to this one?",
  ATTACHMENT = "When determining if links are suspecious, hover over the link or right click to view its destination. Does this redirect to a domain you have heard of and trust? Does this link address make sense in context? Does the link point to a weboage or is it a download link to a resource?",
  LINK = "When determining if attachments are suspecious, were you expecting this email to contain attachments? Does the attachment match the expected file format? Does the attachment have an executable file extension such as .exe .bat or .psl?"
}

export enum DialogEnums {
  CONTENT_DIALOG = "• Look for mistakes or language that would be out of the ordinary for the person that sent this email. • Are there spelling or grammar errors? • A good practice if it's someone you know is to reach out to them in another medium and ask if they sent you an email.",
  SENDER_DIALOG = "• Is this an email from someone you would expect? For example, if it claims to be from Hulu, do you even have a Hulu account? • Check the sender details to see if that's actually where the email was sent from. • Check that the characters in the domain are correct. For example, Mircosoft.com vs Microsoft.com",
  LINK_DIALOG = "• Hover over the link without clicking and look in the bottom left of the browser at what the full URL is. • If possible, never click links in an email and instead navigate to the website yourself through a search engine or manually typing in the known website URL address.",
  QR_CODE_DIALOG = "• When following a QR Code with your camera phone, it will show you the URL address you will be visiting before actually following it. • For the purposes of this testing environment, you can just hover over the QR code with your cursor. • Examine this URL address to make sure it's what you expect it to be.",
  ATTACHMENTS_DIALOG = "• Are there any attachments? If there are: • Look at the file extension. • Some malware can even be initiated just by previewing the attachment. • Don't click or download any attachments if anything else in the email looks phishy. • Use anti-virus to scan email attachments."
}

export enum PhishingExtraHelp {
  SENDER_FIRST_PARAGRAPH = "When determining if a sender is phishy be on the lookout for misspellings in the sender’s domain such as app1e or micr0soft, do a quick google search of the sender’s email address and verify it actually came from the service it claims to be. ",
  SENDER_SECOND_PARAGRAPH = "Do a quick google search of the email address and verify it came from the service it claims to be. " +
    "Phishy emails addresses will commonly show up in google next to the word SCAM from forums " +
    "and communities discussing the failed phishing attempts they’ve encountered. ",
  SENDER_THIRD_PARAGRAPH = "Have you ever sent an email to this person? Click their name to see if there is any conversation " +
    "history. This shows a history of messages between you and the sender and can help verify " +
    "legitimacy to a sender’s purported identity. ",

  CONTENT_FIRST_PARAGRAPH = "When determining if the content of an email is suspicious look for things like misspellings, " +
    "variable placeholders, and context. These emails also often contain keywords such as “urgent”, " +
    "“immediately” or “ASAP” to coerce you into action without a second thought. ",
  CONTENT_SECOND_PARAGRAPH = 'Is this email for a service you subscribe to? Have you seen other similar emails from this sender ' +
    'about this service? Receiving emails for a service you do not have an account with is a dead ' +
    'giveaway of phishy content. ',
  CONTENT_THIRD_PARAGRAPH = "Does the language and tone match what you've experienced with this person? For example: A " +
    "normally laid-back coworker is now demanding you perform an urgent action, which you would " +
    "only expect from a superior. ",

  LINK_FIRST_PARAGRAPH = "When determining if links in an email are suspicious, hover over the link or right click to view its " +
    "destination. Does this link address make sense in context? ",
  LINK_SECOND_PARAGRAPH = "This email alleging to be from Microsoft is redirecting to login.digitalcyber360.net ",
  LINK_THIRD_PARAGRAPH = "Does this redirect to a different domain or one you have heard of and trust? Does the link point " +
    "to a webpage or is it a download link to a resource? ",

  ATTACHMENT_FIRST_PARAGRAPH = "When determining if attachments are suspicious, were you expecting this email to contain " +
    "attachments? Does the attachment match the expected file format? Attachments like resumes " +
    "and spreadsheets or other work-related documents shouldn’t have executable extensions. ",
  ATTACHMENT_SECOND_PARAGRAPH = "Does the attachment have an executable file extension such as .exe .bat or .ps1? The file " +
    "extension should match the context of the file. Also beware of possible macros being shipped in " +
    "document files. \n" +
    "As a basic rule of thumb if you aren’t confident in the legitimacy of an attachment, don’t click " +
    "it.",

  QR_CODE_PARAGRAPH = "• When following a QR Code with your camera phone, it will show you the URL address you will be visiting before actually following it. • For the purposes of this testing environment, you can just hover over the QR code with your cursor. • Examine this URL address to make sure it's what you expect it to be. ",
}

export enum ContentType {
  TEXT,
  Image
}
