<mat-card appearance="outlined">
    <mat-card-header>
        <div class="lines">

            <h1></h1>
            <h2></h2>
        </div>

    </mat-card-header>
    <mat-card-content>
        <div class="lines">

            <h3 *ngFor="let row of list">

            </h3>
            <h2>

            </h2>
        </div>
    </mat-card-content>
    <mat-card-actions>
        <div class="lines">

            <p></p>
        </div>

    </mat-card-actions>
</mat-card>