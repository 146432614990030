<div class="app" style="height: 100%; background-color: var(--main-bg-color);">
    <app-toolbar [ngStyle]="{'width': state ? '79%': '', 'display': state ? 'block': '',
    'opacity':'0.35'}"
    *ngIf="userDataStoreService.getUser()"></app-toolbar>

    <router-outlet>
    </router-outlet>

    <!-- Footer -->
    <div *ngIf="footerUrl != ''" class="footer w-100">
        <span class="px-3 ellipsis">
            {{ footerUrl }}
        </span>
    </div>
</div>
