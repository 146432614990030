<div class="w-100 h-100">
  <div style="height: 6%;" class="mb-1 px-1">
    <div class="d-flex align-items-center p-1 h-100"style="background-color: var(--container-background)">
      <span class="mx-2" style="font-size: 16px">
        <strong>Subject:</strong>
        <span class="ms-2" style="font-size: 15px">{{ subject }}</span>
      </span>
    </div>
  </div>


  <div style="height: 6%;" class="mb-1 px-1">
    <div class="d-flex align-items-center p-1 h-100"
      style="background-color: var(--container-background)">
      <span class="mx-2" style="flex: 1;font-size: 16px">
        <strong>From: </strong>
        <span class="ms-2" style="font-size: 15px">{{ from }}</span>
      </span>
      <span class="mx-2" style="flex: 1;font-size: 16px">
        <strong>To: </strong>
        <span class="ms-2" style="font-size: 15px">{{ loggedInUser.firstName }} {{ loggedInUser.lastName }}</span>
      </span>
    </div>
  </div>


  <div class="row px-3" style="height: 85%;">
    <div class="col-sm-12 pt-3 pb-2 h-100" style=" background-color: var(--container-background)">
      <div *ngIf="attachment.length > 0" class="row" style="margin: 20px;">
        <div *ngFor="let attachmentItem of attachment" class="col-md-12 col-lg-5 col-sm-12 col-12 attachment">
          <mat-icon class="" style="transform: scale(1);">attachment</mat-icon>
          <span style="" class="mx-1">
            {{ attachmentItem }}
          </span>
          <span style="font-size: 10px;" class="">4KB</span>
          <mat-icon class="">expand_more</mat-icon>
        </div>
      </div>

      <div *ngIf="loadingEmailBody" class="d-flex justify-content-center align-items-center">
        <mat-progress-spinner diameter="40" mode="indeterminate">
        </mat-progress-spinner>
      </div>
      <div *ngIf="!loadingEmailBody" style="overflow: auto;" [ngStyle]="{'height': attachment.length ? '82%' : '100%'}">
        <app-email-body [htmlContent]="htmlContent"></app-email-body>
      </div>
    </div>
  </div>
</div>
