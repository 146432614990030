import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {
    AfterContentChecked, AfterContentInit, AfterViewChecked, AfterViewInit, DoCheck, Injectable, OnDestroy, OnInit
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { share } from 'rxjs/operators';
import { HttpServiceFacade } from './http.service.facade';
import {AlertPopupService} from "../services/alert.popup.service";
import {IValidationRule, ServerValidationResult} from "./validation-interface";

@Injectable()
export class BaseForm implements  OnInit, OnDestroy, AfterContentInit, AfterContentChecked,
    DoCheck, AfterViewInit, AfterViewChecked {
    public orphanedValidationRules: Array<IValidationRule> = [];
    public controlGroup!: FormGroup;
    protected promptForLossOfChanges = false;

    reload$: Observable<boolean>;
    protected reloadObserver: any;

    // control group state is still a beta problem an can't be reset
    // need way to force reset so we don't get a prompt after saving
    protected forcePromptBlock = false;
    protected isNew = false;

    // these are the validations for the current control group
    public validationRules: Array<IValidationRule> = [];

    constructor(
                httpServiceFacade: HttpServiceFacade,
                protected alertPopupService: AlertPopupService) {
        httpServiceFacade.onResponse$.subscribe((response) => {
            this.processServerErrorResponse(response);
        });
        this.reload$ = (new Observable<boolean>(observer => this.reloadObserver = observer).pipe(share()));
    }

    ngOnInit() {

        // when control group correctly reflects the state of it's controls and can be reset to a default state (not dirty)
        // resetForm can be removed from the pages
        this.resetForm(true);

        this.onOnInit();

        // because of a bug in controlGroup we need to manually track if a form has unsaved changes
        this.controlGroup.valueChanges
            .subscribe(complete => {
                this.promptForLossOfChanges = this.controlGroup.dirty;
            });

        this.forcePromptBlock = false;
    }

    // use template method design pattern so base form can act on events first
    // ngOnChanges() {
    //     this.onOnChanges();
    // }

    // either the ModelState or a list of ValidationResult.cs
    addValidationErrors(response: HttpErrorResponse) {
        // on response to a failed validation error
        const rules: Array<IValidationRule> = [];
        if (response.error.hasOwnProperty('validation')) {
            const errors = response.error.validation.errors;
            errors.forEach((error: { message: any; field: any; }) => {
                const item: IValidationRule = {
                    key: error.message,
                    field: error.field,
                    message: error.message
                };

                rules.push(item);
            });
        } else {
            this.alertPopupService.error(response.error.details);
        }

        this.checkRules(rules);
    }

    private checkRules(validationRules: Array<IValidationRule>) {
        // the primary goal of this method is to take the results from a server response
        // that either returned ModelState or a collection of ValidationResult.cs
        // with these results we want to update the state of the individual controls and the control group

        this.addNewRules(validationRules);

        this.updateControl(validationRules);

        // force the control group to recompute it's state
        // possible beta bug in control group
        if (validationRules.length > 0) {
            this.controlGroup.updateValueAndValidity();
        }
        if (this.orphanedValidationRules.length > 0) {
            this.controlGroup.setErrors({ globalErrors: true });
        }
    }

    // if we find a validation error has come back but the validation service does not contain a validation entry
    // then add the new rule to the validation rules, these rules are used by the validation display components
    private addNewRules(validationRules: Array<IValidationRule>) {
        validationRules.forEach((item) => {
            const rules = this.validationRules.filter
            (rule => rule.field.toLowerCase() === item.field.toLowerCase() && rule.key === item.key);
            const hasControl = this.controlGroup.controls[item.field];
            const newRule = { field: item.field, key: item.message, message: item.message };

            if (hasControl) {
                // mark the control dirty or the validations won't show up
                hasControl.markAsDirty();

                if (rules.length === 0) {
                    // if there isn't already a validation rule for
                    //  the specific field and key specified to display server side then add it to the list
                    // this is a master list of rules for a given control group
                    this.validationRules.push(newRule);
                }
            } else {
                // this is a list of rules that did not match a given control,
                // we still will want to display them we just can't do it at the control
                this.orphanedValidationRules.push(newRule);
            }
        });
    }

    private updateControl(validationRules: any[]) {
        const controls = this.controlGroup.controls;
        for (const control in controls) {
            if (controls.hasOwnProperty(control)) {

                const brokenRules: Array<ServerValidationResult> = [];

                const controlItem = controls[control];

                const errors = validationRules
                    .filter(validation => validation.field.toLowerCase() === control.toLowerCase());

                // for each of the validators that came back from the server create an item that can be passed to setErrors
                errors.forEach(error => {
                    brokenRules.push({ [error.key]: true });
                });

                // if there are already rules on the control we don't want to lose them so add them to the broken rules list
                const currentErrors = controlItem.errors;

                // if there are current errors we want to also display them,
                // these could be from a server validator and we don't want to clear it
                if (currentErrors) {

                    // if there is only one just push it on the collection
                    if (Object.keys(currentErrors).length === 1) {
                        const onlyOnekey = currentErrors[0];

                        // if there isn't already a broken rule with that key for this control then add it to the list
                        if (brokenRules.filter(item => Object.keys(item)[0] === onlyOnekey).length === 0) {
                            brokenRules.push({ [onlyOnekey]: true });
                        }
                    } else {
                        // if there is more than one and it doesn't already exist on the control
                        // the first property is the key based on the validation structure
                        // if there isn't already a broken rule with that key for this control then add it to the list
                        currentErrors['filter']((currentError: string | number) => {
                            const key = Object.keys(currentErrors[currentError])[0];
                            // if there isn't already a broken rule with that key for this control then add it to the list
                            if (brokenRules.filter(item => Object.keys(item)[0] === key).length === 0) {
                                brokenRules.push({ [key]: true });
                            }
                        });
                    }
                }
                // set all the errors on the control
                if (brokenRules.length > 0) {
                    // if there is only one push it directly
                    if (brokenRules.length === 1) {
                        controlItem.setErrors(brokenRules[0]);
                    } else {
                        controlItem.setErrors(brokenRules);
                    }
                }
            }
        }
    }

    // when validation fails a BadRequest is sent back from the server which will have a status of 400

    private processServerErrorResponse(response: Observable<HttpResponse<any>>) {
        response.subscribe(() => { },
            error => {
                if (error.status === 400) {
                    this.orphanedValidationRules = [];
                    this.addValidationErrors(error);
                }
            });
    }

    ngDoCheck() {
        this.onDoCheck();
    }

    ngOnDestroy() {
        this.onOnDestroy();
        this.reload$ = of(false);
    }

    ngAfterContentInit() {
        this.onAfterContentInit();
    }

    ngAfterContentChecked() {
        this.onAfterContentChecked();
    }

    ngAfterViewInit() {
        this.onAfterViewInit();
    }

    ngAfterViewChecked() {
        this.onAfterViewChecked();
    }

    // rebuilds the form, this is needed because it's currently not possible to reset the state of the controlGroup
    // there are other issues with controlGroup where it doesn't accurately reflect the proper state based on the controls it contains
    protected resetForm(isInit: boolean) {
        this.onResetForm(isInit);

        this.controlGroup.valueChanges
            .subscribe(complete => {
                this.promptForLossOfChanges = this.controlGroup.dirty;
            });

        this.promptForLossOfChanges = false;
    }

    // currently the select lists does not work correctly in IE and the state of the control needs to be manually triggered
    protected selectListWorkAround(control: FormControl, value: any) {
        control.setValue(value);
        control.markAsDirty();
        control.updateValueAndValidity();

        // the issue with the controlGroup not having the correct value for it's stand and it's inability to be reset requires
        this.promptForLossOfChanges = true;
    }


    // virtuals
    protected onResetForm(isInit: boolean) { }

    protected onOnInit() { }

    protected onOnChanges() { }

    protected onDoCheck() { }

    protected onOnDestroy() { }

    protected onAfterContentInit() { }

    protected onAfterContentChecked() { }

    protected onAfterViewInit() { }

    protected onAfterViewChecked() { }

    public fieldErrors(field: string): Array<string> {
        const control = this.controlGroup.get(field);
        if (control && !control.valid && (control.touched || control.dirty)) {
            return this.validationRules.filter(rule => rule.field === field)
                .filter(rule => control.hasError(rule.message) || control.hasError(rule.key))
                .map(rule => rule.message);
        }
        return [];
    }

    public updateValidationErrorMessage(key: string, field: string, message: string) {
        for (var i = 0; i < this.validationRules.length; i++) {
            if (this.validationRules[i].key == key && this.validationRules[i].field == field) {
                this.validationRules[i].message = message;
                break;
            }
        }
    }
}
