<mat-toolbar class="p-0 px-1 toolbar justify-content-between" style="height: 48px">
    <div class="d-flex align-items-center">
        <a routerLink="/home" style="text-decoration: none;">
            <span class="ms-3" *ngIf="location.path().includes('home')">
                {{ userDataStoreService.getUserEmailClient() }}
            </span>
            <span class="d-flex ms-1" *ngIf="!location.path().includes('home')">
                <mat-icon class="me-1">arrow_back</mat-icon>
                <span style="font-size: large;">Return to simulation</span>
            </span>
        </a>
    </div>

    <div *ngIf="router.url == '/home'"
         class="d-flex mx-5">
    </div>

    <div class="d-flex" *ngIf="shouldShowCustomerPicker()">
        <app-customer-picker></app-customer-picker>
    </div>

    <div *ngIf="showSettingsIcons"
        class="d-flex justify-content-between me-3" style="width: 120px">
        <app-theme-toggle></app-theme-toggle>

        <a (click)="navigateToCustomer()" [class.disabled]="userDataStoreService.isEXEMode()" style="text-decoration: none">
            <mat-icon class="btn-icon">settings_outline</mat-icon>
        </a>

        <mat-icon *ngIf="userService.getAllowLogout()" (click)="startOver()" class="btn-icon">logout</mat-icon>
    </div>

</mat-toolbar>
