import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ViewEmailComponent } from './view-email/view-email.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SanitizeHtmlPipe } from './pipes/SanitizeHTML.pipe';
import { FileReaderService } from './file-reader.service';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MailToolbarComponent } from './mail-toolbar/mail-toolbar.component';
import { MailSidenavComponent } from './mail-sidenav/mail-sidenav.component';
import { LoginComponent } from './login/login.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './home/home.component';
import { EmailListingComponent } from "./EmailListing/email-listing.component";
import { IpcService } from "./ipc-service";
import { MainComponent } from './main/main.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { ScoringPanelComponent } from "./scoring-panel/scoring-panel.component";
import { ReusableDialogComponent } from './reusable-dialog/reusable-dialog.component';
import { FinalScoreComponent } from './final-score/final-score.component';
import { MultipleSlidesDialogComponent } from "./dialog-with-tabs/multiple-slides-dialog.component";
import { ThemeToggleComponent } from './theme-toggle/theme-toggle.component';
import { HttpServiceFacade } from './shared/http.service.facade';
import { RequestHandlingInterceptor } from './core/Interceptors/request-handling.interceptor';
import { JwtInterceptor } from './core/Interceptors/JWT.interceptor';
import { SignupComponent } from "./signup/signup.component";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { WebsocketService } from './core/websocket/websocket.service';
import { SharedModule } from './shared/shared.module';
import { ErrorInterceptor } from './core/Interceptors/error.interceptor';
import { PdfViewerModule } from "ng2-pdf-viewer";
import {CommonModule} from "@angular/common";
import {LoginErrorComponent} from "./login/login-error/login.error.component";
import { EmailBodyComponent } from './view-email/email-body/email-body.component';
import {InfiniteScrollModule} from "ngx-infinite-scroll";

@NgModule({
    declarations: [
        AppComponent,
        ViewEmailComponent,
        EmailListingComponent,
        AppComponent,
        ToolbarComponent,
        MailToolbarComponent,
        MailSidenavComponent,
        LoginComponent,
        LoginErrorComponent,
        SignupComponent,
        HomeComponent,
        MainComponent,
        WelcomeComponent,
        ScoringPanelComponent,
        FinalScoreComponent,
        ReusableDialogComponent,
        MultipleSlidesDialogComponent,
        ThemeToggleComponent,
        EmailBodyComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        SharedModule.forRoot(),
        BrowserAnimationsModule,
        NgbModule,
        NgxExtendedPdfViewerModule,
        PdfViewerModule,
        CommonModule,
        InfiniteScrollModule
    ],

    providers: [IpcService, FileReaderService, HttpServiceFacade,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestHandlingInterceptor,
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        WebsocketService
    ],
    exports: [
        ToolbarComponent,
        MailSidenavComponent,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
