export interface Country {
    name: string;
    states: Array<State>
}
export interface State {
    name: string;
    cities: Array<string>
}

export enum CountryRefreshEnum {
    Country_Refresh,
    State_Refresh,
    City_Refresh
}
