import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { HttpServiceFacade } from '../http.service.facade';
import {Country} from "../model/country.model";

@Injectable()
export class CountryService {
    countriesLoaded: ReplaySubject<boolean> = new ReplaySubject();
    countries: Array<Country> = new Array();
    constructor(private backendService: HttpServiceFacade) {
        this.fetchCountries()
    }
    fetchCountries(): void {
        this.backendService.get<Array<Country>>('assets/all+countries+states+cities.json').subscribe(
            (countries) => {
                this.countries = countries;
                this.countriesLoaded.next(true);
            }
        )

    }
    getCountries(): Observable<Array<Country>> {

        return of(this.countries);
    }

}
