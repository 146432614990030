<div class="d-flex justify-content-center br-5" style="background: var(--container-background)">
    <div class="main-card px-5 m-5">
        <h1 class="mb-5"><strong>You've completed the phishing simulation!</strong></h1>
        <div class="m-4 d-flex flex-column justify-content-center align-items-center">
            <h3><strong>Your score is: ({{emails.score}} of {{emails.total}}) {{emails.percentage}}</strong></h3>
            <div>
                <button class="score-btn mt-5 mx-2 px-4" (click)="restart()">Restart</button>
                <button class="score-btn mt-5 mx-2 px-4" (click)="onSubmitScoreBtnClick()">Submit Score</button>
            </div>
        </div>
    </div>
</div>