import { Component, Input, OnInit } from '@angular/core';
import {SettingGroup, SettingPageEnum} from '../../model/setting.model';

@Component({
  selector: 'app-setting-page',
  templateUrl: './setting-page.component.html',
  styleUrls: ['./setting-page.component.scss']
})
export class SettingPageComponent implements OnInit {
  @Input() settingGroups!: SettingGroup[];
  @Input() page!: SettingPageEnum;
  @Input() customerId!: number;

  constructor() { }

  ngOnInit(): void {
  }
}
