import {Observable} from "rxjs";
import {HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {HelperFileTypesEnum} from "../models/Util";
import {HttpServiceFacade} from "../shared/http.service.facade";

@Injectable({
    providedIn: 'root'
})
export class UtilService {
    constructor(
        private backendService: HttpServiceFacade
    ) {
    }

    loadHelperFile(type: HelperFileTypesEnum): Observable<any> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("type", type)
        return this.backendService.downloadFile("/api/util/helper", httpParams);
    }
}
