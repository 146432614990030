<div class="d-flex" [formGroup]="controlGroup">
    <mat-form-field subscriptSizing="dynamic" appearance="outline" class="dense-3" style="background: white;width: 270px">
        <mat-select formControlName="customer" (selectionChange)="onCustomerSelected($event)">
            <mat-option *ngIf="isLoadingCustomerCandidates" class="p-3 row">
            <span class="col-12 d-flex flex-row justify-content-center">
                <mat-spinner color="primary" diameter="20" strokeWidth="1"></mat-spinner>
            </span>
            </mat-option>
            <ng-container *ngIf="!isLoadingCustomerCandidates">
                <mat-option *ngFor="let customer of customers" [value]="customer.id">
                    {{ customer.name }}
                </mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>
</div>
